import React, { useState, useEffect, useCallback } from "react";
import { Carousel, CarouselItem, Container, Accordion, Placeholder } from "react-bootstrap";
import { useGlobalContext } from "./GlobalContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate, faCheck, faKey, faEuroSign, faHandshakeSimple } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import LoadingButton from "./LoadingButton"
import {
  collection,
  onSnapshot,
  query,
  where,
  limit
} from "firebase/firestore";
import { Timestamp } from "firebase/firestore";

function Homepage() {
  const { db, user, getLicencie, PageUrl, dbAvailable, getDateTrainingWithDay, getDateTraining, getPhotoEvent } = useGlobalContext();
  const idFeed = "sharethis-social-feed-652e71ae2dc05000197e5cb7";
  const [nextEvents, setNextEvents] = useState([]);
  const [nextTrainings, setNextTrainings] = useState([]);
  const [index, setIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  const differenceMinutes = Math.floor(
    (new Date() - db.lastRefresh) / (60 * 1000)
  );
  let depuis = "Mis à jour ";
  if (differenceMinutes < 1) {
    depuis += " à l'instant";
  } else if (differenceMinutes < 10) {
    depuis += " il y a quelques minutes";
  } else if (differenceMinutes < 60) {
    depuis += " il y a " + differenceMinutes + " minutes";
  } else {
    depuis += " il y a " + Math.floor(differenceMinutes / 60) + " heures";
  }

  const addParticipant = async (newTraining) => {
    await db.addParticipant(newTraining.creneauUid, newTraining.uid, user.uid);
  };

  const removeParticipant = async (training) => {
    await db.removeParticipant(training.creneauUid, training.uid, user.uid);
  };

  const addEncadrant = async (newTraining) => {
    await db.addEncadrant(newTraining.creneauUid, newTraining.uid, user.uid);
  };

  const removeEncadrant = async (training) => {
    await db.removeEncadrant(training.creneauUid, training.uid, user.uid);
  };

  const getEncadrants = useCallback(async (training) => {
    const retour = [];
    for (const encUid of training["encadrants"]) {
      const curLic = await getLicencie(encUid);
      if (curLic) retour.push(curLic);
    }
    return retour;
  }, [getLicencie]);

  const getParticipants = useCallback(async (training) => {
    const retour = [];
    for (const encUid of training["participants"]) {
      const curLic = await getLicencie(encUid);
      if (curLic) retour.push(curLic);
    }
    return retour;
  }, [getLicencie]);

  useEffect(() => {
    const unsubscribeTrainings = [];
    let load2 = 0;
    let retour = [];
    const date = Timestamp.now();
    const q = query(collection(db.db, "clubs/badlevier/créneaux"));
    const unsubscribeCreneau = onSnapshot(q, async (querySnapshotCreneau) => {
      const nbCreneau = querySnapshotCreneau.docs.length;
      await Promise.all(
        querySnapshotCreneau.docs.map(async (doc) => {
          const creneau = doc.data();
          const hDebut = creneau.heureDebut.split(":")[0];
          const mDebut = creneau.heureDebut.split(":")[1];
          const hFin = creneau.heureFin.split(":")[0];
          const mFin = creneau.heureFin.split(":")[1];
          const hDuree = hFin - hDebut;
          const mDuree = mFin - mDebut;
          const dateFin = date.toDate();
          dateFin.setHours(dateFin.getHours() - hDuree);
          dateFin.setMinutes(dateFin.getMinutes() - mDuree);
          const q1 = query(collection(db.db, "clubs/badlevier/créneaux/" + creneau.uid + "/entraînements"), where("date", ">=", Timestamp.fromDate(dateFin)), limit(1));
          const unsubscribeChild = await onSnapshot(q1, async (querySnapshotEntrainement) => {
            await Promise.all(
              querySnapshotEntrainement.docs.map((async (doc) => {
                const training = doc.data();
                training["creneau"] = creneau;
                training["encadrants"] = await getEncadrants(training);
                training["participants"] = await getParticipants(training);
                const creneauFindIndex = retour.findIndex(entrainement => entrainement.creneauUid === creneau.uid);
                if (creneauFindIndex > -1) {
                  retour[creneauFindIndex] = training;
                } else {
                  retour.push(training);
                  load2++;
                }
                retour = retour.sort((ent1, ent2) =>
                  ent1.date.toDate() > ent2.date.toDate() ? 1 : -1
                )
                setNextTrainings([...retour]);
                if (load2 >= nbCreneau) {
                  setLoading(false);
                }
              })));
          });
          unsubscribeTrainings.push(unsubscribeChild);
        }));
    });


    const q1 = query(collection(db.db, "clubs/badlevier/events"), where("visible", "==", true), limit(3));
    const unsubscribeEvent = onSnapshot(q1, async (querySnapshotEvents) => {
      const retour = [];
      await Promise.all(
        querySnapshotEvents.docs.map(async (doc) => {
          const data = doc.data();
          retour.push(data);
        }));
      setNextEvents([...retour]);
    });

    return () => {
      unsubscribeCreneau();
      unsubscribeTrainings.forEach(un => un());
      unsubscribeEvent();
    };
  }, [db, setNextTrainings, getParticipants, getEncadrants]);


  return (
    <Container>
      {dbAvailable ?
        (user ?
          (<>
            <h3
              className="section"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              Prochains entraînements
            </h3>
            {loading ?
              <Placeholder as="div" animation="glow" style={{ width: '100%', height: "100px", marginBottom: "10px" }} >
                <Placeholder style={{ width: '100%', height: "100%", borderRadius: "5px" }} />
              </Placeholder>
              : nextTrainings.length === 0 ? (
                <span style={{ fontStyle: "italic" }}>
                  Aucun prochain entraînement
                </span>
              ) : (
                <Accordion style={{ marginBottom: "10px" }}>
                  {nextTrainings.map((training, indexKey) => {
                    const currentCreneau = training["creneau"];
                    const isParticipe = user && training["participants"].filter(p => p.uid === user.uid).length > 0;
                    const encadrants = training["encadrants"];
                    const participants = training["participants"];
                    const isEncadrantValid = user && currentCreneau["encadrants"].includes(user.uid);
                    const isEncadre = user && training["encadrants"].filter(p => p.uid === user.uid).length > 0;
                    return (
                      <Accordion.Item eventKey={indexKey} key={indexKey}>
                        <Accordion.Header>
                          <div style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                            height: "100%",
                            //backgroundColor: "white",
                            borderRadius: "5px",
                            padding: "5px",
                            flexDirection: "column"
                            //flexWrap: "wrap",
                          }}>
                            <div style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                              alignItems: "flex-start",
                              height: "100%",
                              //backgroundColor: "white",
                              borderRadius: "5px",
                              //flexWrap: "wrap",
                            }}>
                              <div style={{
                                display: "flex",
                                flex: "1 1 auto",
                                justifyContent: "flex-start",
                                alignItems: "center",
                              }}>

                                <div style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "space-between",
                                  alignItems: "flex-start",
                                  flexWrap: "wrap",
                                  marginLeft: "5px"
                                }}>
                                  <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "flex-start",
                                    flexWrap: "wrap",
                                  }}>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      height="1em"
                                      viewBox="0 0 512 512"
                                      style={{ marginLeft: "5px", marginRight: "5px" }}
                                    >
                                      <path
                                        fill="rgb(28, 105, 28)"
                                        d="M480 192h-61.6l10.4-72.6c3-20.8-14.5-39.1-36.2-36.2L320 93.6V32c0-17.7-14.3-32-32-32h-43.1c-12.2 0-23.5 7.1-28.8 18.1l-118 245.8-67 67c-41.4 41.4-41.4 108.6 0 150 20.7 20.7 47.8 31.1 75 31.1 27.1 0 54.3-10.4 75-31.1l67-67 245.8-118c11-5.3 18.1-16.6 18.1-28.8V224c0-17.7-14.3-32-32-32zm-82.9-77.1l-11.4 80.2L294 218l22.9-91.7 80.2-11.4zM244.9 32H288v70.3l-102.4 53.3L244.9 32zm35 110.6l-22.3 89.2-87.8 87.8-42.4-42.4 35.2-73.5 117.3-61.1zM32 406c0-18.6 7-36 19.5-49.6l104.2 104.2C142 473 124.6 480 106 480c-40.9 0-74-33.1-74-74zm146.5 32.2L73.8 333.5l32.2-32.2L210.7 406l-32.2 32.2zm56.3-53.5l-42.4-42.4 87.8-87.8 89.2-22.3-61.1 117.3-73.5 35.2zM480 267l-123.6 59.4L409.7 224H480v43z"
                                      />
                                    </svg>
                                    <b><span style={{ backgroundColor: "rgb(226, 240, 226)", padding: "3px", margin: "0px 2px" }}>{currentCreneau.type}</span></b>

                                  </div>
                                  <div style={{ display: "flex", flexDirection: "column", flexWrap: "wrap", alignItems: "center", margin: "5px", padding: "5px", borderRadius: "3px", /*backgroundColor: "rgb(226, 240, 226)", color: "black"*/ }}>
                                    <span style={{ marginBottom: "5px" }}>{getDateTrainingWithDay(training.date)}</span>
                                    <span style={{ marginBottom: "5px" }}>à {currentCreneau.heureDebut.replace(":", "h")}</span>
                                    {training.lieu !== currentCreneau.lieu && (
                                      <span style={{ backgroundColor: "#f9f995", padding: "5px", borderRadius: "3px", textAlign: "center" }}>{training.lieu}</span>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-end" }}>
                                {db.isMemberClub ? (
                                  isParticipe ? (
                                    <div style={{ fontSize: "0.8em", display: "flex", flex: "1 0 auto", flexDirection: "column", alignItems: "flex-end" }}>
                                      <LoadingButton
                                        className="btn btn-custom1"
                                        style={{ marginTop: "10px", fontSize: "0.8em" }}
                                        onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}
                                        onClickPromise={removeParticipant}
                                        additionalParam={[training]}
                                      >
                                        <span style={{ marginRight: "5px" }}> <FontAwesomeIcon
                                          style={{ marginRight: "5px" }}
                                          icon={faCheck}
                                        />Inscrit</span>
                                      </LoadingButton>
                                    </div>
                                  ) : (
                                    <LoadingButton
                                      className="btn btn-custom2"
                                      style={{ marginTop: "10px", fontSize: "0.8em" }}
                                      onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}
                                      onClickPromise={addParticipant}
                                      additionalParam={[training]}
                                    >
                                      Je m'inscris
                                    </LoadingButton>
                                  )
                                ) : null}
                                {db.isMemberClub && isEncadrantValid ? (
                                  isEncadre ? (
                                    <div style={{ fontSize: "0.8em", display: "flex", flex: "1 0 auto", flexDirection: "column", alignItems: "flex-end" }}>
                                      <LoadingButton
                                        className="btn btn-custom1"
                                        style={{ marginTop: "10px", fontSize: "0.8em" }}
                                        onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}
                                        onClickPromise={removeEncadrant}
                                        additionalParam={[training]}
                                      >
                                        <span style={{ marginRight: "5px" }}> <FontAwesomeIcon
                                          style={{ marginRight: "5px" }}
                                          icon={faCheck}
                                        />Encadre</span>
                                      </LoadingButton>
                                    </div>
                                  ) : (
                                    <LoadingButton
                                      className="btn btn-custom2"
                                      style={{ marginTop: "10px", fontSize: "0.8em" }}
                                      onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}
                                      onClickPromise={addEncadrant}
                                      additionalParam={[training]}
                                    >
                                      J'encadre
                                    </LoadingButton>
                                  )
                                ) : null}
                                {db.isMemberClub && (
                                  <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", marginRight: "8px" }}>
                                    {(() => {
                                      const nb = training["participants"].length;
                                      if (nb === 0) {
                                        return <span style={{ fontSize: "0.8em", fontStyle: "italic", color: "gray", marginBottom: "10px" }}>Aucun inscrit</span>
                                      } else if (nb === 1) {
                                        return <span style={{ fontSize: "0.8em", fontStyle: "italic", color: "gray", marginBottom: "10px" }}>1 inscrit</span>
                                      } else {
                                        return <span style={{ fontSize: "0.8em", fontStyle: "italic", color: "gray", marginBottom: "10px" }}>{nb} inscrits</span>
                                      }
                                    })()}

                                    {(() => {
                                      const partAndEnc = training ? training["participants"].concat(training["encadrants"]) : [];
                                      const keyPasser = training ? partAndEnc.filter(u => u.keyPass).map(u => `${u.displayName}`).join(", ") : null;
                                      if (keyPasser === null || keyPasser === "") {
                                        return <span style={{ fontSize: "0.8em", fontWeight: "500", fontStyle: "italic", color: "rgb(255, 80, 10)", marginBottom: "10px" }}>
                                          <FontAwesomeIcon
                                            style={{ marginRight: "5px" }}
                                            icon={faKey}
                                          />
                                          Pas d'accès</span>
                                      } else if (!keyPasser.includes(",")) {
                                        return <div title={"peut ouvrir/fermer le gymnase"} style={{ maxWidth: "100px", display: "flex", justifyContent: "flex-end", fontSize: "0.8em", fontStyle: "italic", color: "gray", marginBottom: "10px" }}>

                                          <span style={{ textAlign: "right" }}><FontAwesomeIcon
                                            style={{ marginRight: "3px" }}
                                            icon={faKey}
                                          />{keyPasser}</span>
                                        </div>
                                      } else {
                                        return <div title={"Peuvent ouvrir/fermer le gymnase"} style={{ maxWidth: "100px", display: "flex", justifyContent: "flex-end", alignItems: "center", fontSize: "0.8em", fontStyle: "italic", color: "gray", marginBottom: "10px" }}>
                                          <span style={{ textAlign: "right", marginLeft: "5px" }}><FontAwesomeIcon
                                            style={{ marginRight: "3px" }}
                                            icon={faKey}
                                          />{keyPasser}</span>
                                        </div>
                                      }
                                    })()}
                                  </div>
                                )}
                              </div>
                            </div>
                            {db.isMemberClub &&
                              (encadrants.length === 0 ? null : (
                                <div style={{ width: "-webkit-fill-available" }}>
                                  <label>
                                    Encadrant{encadrants.length > 1 && "s"}
                                    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-end" }}>
                                      {encadrants.length !== 0 ? (encadrants.map((encadrant, indexP) => (
                                        <span key={indexP} style={{ borderBottom: encadrant.keyPass ? "1px solid gray" : "" }} >
                                          {encadrant.keyPass && (
                                            <FontAwesomeIcon
                                              style={{ marginRight: "2px", height: "0.8em" }}
                                              icon={faKey}
                                            />
                                          )}
                                          {encadrant.displayName}
                                        </span>
                                      ))) : (
                                        <span>-</span>
                                      )}
                                    </div>
                                  </label>
                                </div>
                              ))}
                            {training.description !== "" && (
                              <span style={{ backgroundColor: "#f9f995", padding: "5px", borderRadius: "3px", textAlign: "center", width: "-webkit-fill-available" }}>{training.description}</span>
                            )}
                          </div>
                        </Accordion.Header>
                        <Accordion.Body style={{ backgroundColor: "rgb(190 190 190)", fontSize: "0.9em" }}>
                          {training.lieu === currentCreneau.lieu ? (
                            <label>
                              Lieu
                              <span>{training.lieu}</span>
                            </label>
                          ) : (
                            <label style={{ width: "100%" }}>
                              Lieu
                              <div style={{ fontSize: "0.8em", display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "flex-end" }}>
                                <strike> <span style={{ marginBottom: "5px" }}>{currentCreneau.lieu}</span></strike>
                                <span style={{ backgroundColor: "yellow", padding: "3px" }}>{training.lieu}</span>
                              </div>
                            </label>
                          )}
                          {db.isMemberClub &&
                            <div>
                              <label>
                                Encadrant{encadrants.length > 1 && "s"}
                                <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-end" }}>
                                  {encadrants.length !== 0 ? (encadrants.map((encadrant, indexP) => (
                                    <span key={indexP} style={{ borderBottom: encadrant.keyPass ? "1px solid gray" : "" }} >
                                      {encadrant.keyPass && (
                                        <FontAwesomeIcon
                                          style={{ marginRight: "2px", height: "0.8em" }}
                                          icon={faKey}
                                        />
                                      )}
                                      {encadrant.displayName}
                                    </span>
                                  ))) : (
                                    <span>-</span>
                                  )}
                                </div>
                              </label>
                            </div>
                          }
                          {db.isMemberClub && (
                            <div>
                              <label>
                                Participants
                                <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-end" }}>
                                  {participants.length !== 0 ? (participants.map((participant, indexP) => (
                                    <span key={indexP} style={{ borderBottom: participant.keyPass ? "1px solid gray" : "" }} >
                                      {participant.keyPass && (
                                        <FontAwesomeIcon
                                          style={{ marginRight: "2px", height: "0.8em" }}
                                          icon={faKey}
                                        />
                                      )}
                                      {participant.displayName}
                                    </span>
                                  ))) : (
                                    <span>-</span>
                                  )}
                                </div>
                              </label>
                            </div>
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <LoadingButton
                      className="btn btn-custom3"
                      style={{ display: "none", alignItems: "center" }}
                      contentLoading="Mise à jour en cours ..."
                    >
                      <span style={{ marginRight: "10px" }}>{depuis}</span>
                      <FontAwesomeIcon
                        icon={faArrowsRotate}
                      />
                    </LoadingButton>

                  </div>
                </Accordion>
              )
            }
          </>
          )
          : null)
        : <>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", padding: "20px", backgroundColor: "lightyellow" }}>
            <span style={{ fontWeight: "bold" }} >Site en maintenance </span>
            <span style={{ fontStyle: "italic" }} >Veuillez nous excuser de la gêne occasionnée</span>
          </div>
          <div className="offcanvas-body">
            <ul style={{ flex: "1 1 auto" }}>
              <li>
                <Link to={PageUrl.CLUB}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 640 512"
                  >
                    <path
                      fill="rgb(28, 105, 28)"
                      d="M615.5 59.6C560.6-17.9 433.3-19.2 332.7 52c-57.2 40.5-94.4 96-106.5 150.6-10.7 48.2-34.6 91.7-66.1 129.2-17.9-10-32.2.2-33.9 1.4L13.6 412c-14.4 10.1-18 30-7.8 44.5l29.4 41.9c9.9 14.1 29.7 18.2 44.5 7.8l112.6-78.8c9.5-6.7 13.7-17.6 13-28.4 36-13.7 73.8-21.7 112.3-21.7 31.6 0 112.3 21.8 211.5-48.4 101.8-72.2 140.6-192.8 86.4-269.3zM61.3 480l-29.4-41.8 112.6-78.8 29.4 41.9L61.3 480zm130-109.6l-9.7-13.9c18.7-21.7 34.8-44.9 47.4-69.6 11 33.1 29.1 49.8 44.4 61.5-27.6 3.9-55.2 11.5-82.1 22zm319.2-67.6c-85.6 60.6-194 62.4-238.3-.1-43.9-62-8.5-162.7 79-224.7 84.7-60 193.6-63.1 238.3 0 42 59.4 11.1 161.1-79 224.8z"
                    />
                  </svg>
                  Le club
                </Link>
              </li>
              <li>
                <Link to={PageUrl.INSCRIPTION}>
                  <FontAwesomeIcon icon={faEuroSign} />
                  Tarifs et inscriptions
                </Link>
              </li>
              <li>
                <Link to={PageUrl.PARTNERS} >
                  <FontAwesomeIcon icon={faHandshakeSimple} />
                  Partenaires
                </Link>
              </li>
              <li>
                <Link to={PageUrl.CONTACT} >
                  <i className="bi bi-envelope"></i>
                  Contact
                </Link>
              </li>
              <hr />
              <li>
                <Link to={PageUrl.GENERATOR} >
                  <i className="bi bi-dice-3"></i>
                  <span>Générateur de tournoi</span>
                </Link>
              </li>
            </ul>
          </div>
        </>
      }

      {dbAvailable &&
        (<>
          <h3
            className="section"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            À venir
          </h3>
          <div className="carousel-container">
            {loading ?
              <Placeholder as="div" animation="glow" style={{ width: '100%', height: "100px", marginBottom: "10px" }} >
                <Placeholder style={{ width: '100%', height: "100%", borderRadius: "5px" }} />
              </Placeholder>
              : nextEvents.length === 0 ? (
                <span style={{ fontStyle: "italic" }}>
                  Aucun prochain événement
                </span>
              ) : (
                <div>
                  <Carousel
                    activeIndex={index}
                    onSelect={handleSelect}
                    style={{
                      width: "100%", height: "500px",
                      backgroundColor: "white",
                      borderRadius: "5px"
                    }}
                  >
                    {nextEvents.map((ev, indexKey) => {

                      return (
                        <CarouselItem key={indexKey} interval={5000}
                          style={{
                            height: "100%"
                          }}
                        >
                          <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            fontWeight: "bold",
                            padding: "10px",
                            width: "100%",
                            height: "100%",
                            paddingBottom: "35px"
                          }}>
                            {ev.title !== "" &&
                              <span style={{
                                zIndex: "1",
                                backgroundColor: "white",
                                padding: "3px 5px",
                                borderRadius: "3px",
                                fontSize: "1.2em"
                              }}>
                                <b>{ev.title}</b>
                              </span>
                            }
                            {ev.dateDebut !== "" || ev.dateFin !== "" ?
                              (<span style={{
                                zIndex: "1",
                                marginBottom: "10px",
                                padding: "3px 5px",
                                borderRadius: "3px",
                                fontSize: "0.8em",
                                textAlign: "center"
                              }}>
                                {(ev.dateDebut !== "" && ev.dateDebut.toDate().getTime() === ev.dateFin.toDate().getTime()) ?
                                  <div style={{ opacity: "0.8" }}>
                                    {getDateTraining(ev.dateDebut)}
                                    <br />
                                    <span>{ev.heureDebut !== "" && "de " + ev.heureDebut.replace(":", "h")} {ev.heureFin !== "" && "à " + ev.heureFin.replace(":", "h")}</span>
                                  </div>
                                  :
                                  <div style={{ opacity: "0.8" }}>
                                    {ev.dateDebut !== "" &&
                                      <span>Du {getDateTraining(ev.dateDebut)}</span>
                                    }
                                    {ev.heureDebut !== "" &&
                                      <span>{ev.heureDebut !== "" && " à " + ev.heureDebut.replace(":", "h")}</span>
                                    }
                                    {(ev.dateDebut !== "" || ev.heureDebut !== "") &&
                                      <br />
                                    }
                                    {ev.dateFin !== "" &&
                                      <span>Au {getDateTraining(ev.dateFin)}</span>
                                    }
                                    {ev.heureFin !== "" &&
                                      <span>{ev.heureFin !== "" && " à " + ev.heureFin.replace(":", "h")}</span>
                                    }
                                  </div>}
                              </span>)
                              : null}
                            {ev.photoUrl !== "" &&
                              <div
                                className="divPhotoEvent"
                                style={{
                                  display: "flex",
                                  //flex: "1 1 auto",
                                  alignItems: "center",
                                  justifyContent: "space-around",
                                  maxHeight: "100%",
                                  overflow: "auto",
                                  flexDirection: "column"
                                  //marginBottom: "20px"
                                }}>
                                {ev.photoUrl !== "" &&
                                  getPhotoEvent(ev.photoUrl, ev.photoUrl ? ev.title : null, {
                                    maxHeight: "300px",
                                    maxWidth: "300px",
                                    borderRadius: "3px",
                                    //opacity: ev.title === "" ? "1" : "0.7",
                                    //position: "absolute",
                                    //left: "50%",
                                    //top: "50%",
                                    //transform: "translate(-50%, -50%)",
                                  })
                                }
                              </div>
                            }
                            {ev.description !== "" &&
                              <span style={{
                                backgroundColor: "white",
                                padding: "3px 5px",
                                borderRadius: "3px",
                                fontSize: "0.8em",
                                flex: "1 1 auto",
                                textAlign: "center",
                                zIndex: "1"
                              }}>
                                {ev.description}
                              </span>
                            }

                          </div>
                        </CarouselItem>
                      );
                    })}
                  </Carousel>
                </div>
              )}
          </div>
        </>
        )
      }
      <h3
        className="section"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        Actualités
      </h3>
      {/*Feed facebook dispo sur shareThis.com*/}
      < div
        style={{ position: "relative" }}
        className="powr-social-feed"
        id={idFeed}
      ></div>
    </Container >
  );
}

export default Homepage;
