import React, { useState, useEffect } from "react";
import { useGlobalContext } from "./GlobalContext";
import { Container, Button, Badge, Accordion, Tabs, Tab, ButtonGroup, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faShoppingCart, faWallet, faEye, faCheck } from "@fortawesome/free-solid-svg-icons";
import ModalMouvement from "./ModalMouvement.js";
import StatusTransaction from "./StatusTransaction.js";
import {
  onSnapshot,
  query,
  collection,
  doc
} from "firebase/firestore";
import RemiseMembre from "./RemiseMembre.js";
import ModeReglement from "./ModeReglement.js";
import { Timestamp } from "firebase/firestore";

function ManagementTresorerie() {
  const {
    db,
    user,
    getLicencie,
    StatusMouvement,
    TypeMouvement,
    TypeReglement,
    getDateTraining,
    handleShowConfirmation,
    handleShowAlert,
    handleCloseConfirmation,
    TypeMotifStock
  } = useGlobalContext();
  const [mouvements, setMouvements] = useState([])
  const [mouvementsAllUser, setMouvementsAllUser] = useState([])
  const [currentTypeMouvement, setCurrentTypeMouvement] = useState(null);
  const isAdmin = db.isAdmin;
  const isTresorier = db.isTresorier;
  const [modalMouvementShow, setModalMouvementShow] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [showMouvementClotureDepot, setShowMouvementClotureDepot] = useState(false);
  const [showMouvementClotureAchat, setShowMouvementClotureAchat] = useState(false);
  const [articles, setArticles] = useState([]);
  const [membresBureau, setMembresBureau] = useState([]);

  const ajoutCommande = () => {
    setCurrentTypeMouvement(TypeMouvement.ACHAT);
    setModalMouvementShow(true);
  };
  const ajoutDepot = () => {
    setCurrentTypeMouvement(TypeMouvement.DEPOT);
    setCurrentUser(null);
    setModalMouvementShow(true);
  };

  const onValid = () => {
    setModalMouvementShow(false);
  };

  const onCancel = () => {
    setModalMouvementShow(false);
  };

  const onValidMouvement = async (mouvement) => {
    const lic = mouvement.licencie;
    if (lic === null) return;

    const newStatus = mouvement.typeMouvement === TypeMouvement.DEPOT || mouvement.alreadyDistrib ? StatusMouvement.CLOTURE : StatusMouvement.ATTENTE_DISTRIBUTION;
    await db.updateMouvement(mouvement?.uid, {
      status: newStatus,
    });
    if (mouvement.typeMouvement === TypeMouvement.DEPOT) {
      const newMontant = (lic["porte-feuille"] + mouvement.montant);
      await db.updateLicencie(mouvement.licencie?.uid, {
        "porte-feuille": newMontant
      });
    } else if (mouvement.typeReglement === TypeReglement.PORTE_FEUILLE) {
      await db.addMouvement(
        {
          mouvementAchat: mouvement?.uid,
          typeMouvement: TypeMouvement.DEPOT,
          motif: "Retrait",
          typeReglement: mouvement.typeReglement,
          montant: -mouvement.montant,
          status: StatusMouvement.CLOTURE,
          remiseMembreBureau: mouvement.remiseMembreBureau?.uid,
          licencie: mouvement.licencie?.uid,
          createur: user?.uid
        }, true);
    }

    if (newStatus === StatusMouvement.CLOTURE) {
      //on déplace le mouvement sur la personne
      mouvement.status = newStatus;
      mouvement.createur = mouvement.createur?.uid;
      mouvement.licencie = mouvement.licencie?.uid;
      mouvement.remiseMembreBureau = mouvement.remiseMembreBureau ? mouvement.remiseMembreBureau?.uid : null;
      db.addMouvement(mouvement, true);
      db.removeMouvement(mouvement?.uid);
    }


    handleCloseConfirmation();
  };

  const onNotValidMouvement = async (mouvement) => {
    const lic = mouvement.licencie;
    if (lic === null) return;

    if (mouvement.typeMouvement === TypeMouvement.DEPOT) {
      await db.updateMouvement(mouvement?.uid, {
        status: StatusMouvement.ATTENTE_VALIDATION,
      }, mouvement.licencie?.uid, true);
    } else {
      await db.updateMouvement(mouvement?.uid, {
        status: StatusMouvement.ATTENTE_VALIDATION,
      });
    }

    if (mouvement.typeMouvement === TypeMouvement.DEPOT) {
      const newMontant = (lic["porte-feuille"] - mouvement.montant);
      await db.updateLicencie(mouvement.licencie?.uid, {
        "porte-feuille": newMontant
      });
    }

    if (mouvement.typeMouvement === TypeMouvement.DEPOT) {
      //on déplace le mouvement sur le global
      mouvement.status = StatusMouvement.ATTENTE_VALIDATION;
      mouvement.createur = mouvement.createur?.uid;
      mouvement.licencie = mouvement.licencie?.uid;
      mouvement.remiseMembreBureau = mouvement.remiseMembreBureau ? mouvement.remiseMembreBureau?.uid : null;
      db.addMouvement(mouvement);
      db.removeMouvement(mouvement?.uid, mouvement.licencie, true);
    }
    handleCloseConfirmation();
  };

  const onValidPaiement = async (mouvement) => {
    if (mouvement.licencie === null) return;
    const newStatus = mouvement.typeReglement === TypeReglement.PORTE_FEUILLE ? StatusMouvement.ATTENTE_DISTRIBUTION :
      (mouvement.typeReglement === TypeReglement.VIREMENT_BANCAIRE ? StatusMouvement.ATTENTE_VALIDATION : StatusMouvement.ATTENTE_DEPOT);
    await db.updateMouvement(mouvement?.uid, {
      status: newStatus,
      typeReglement: mouvement.typeReglement,
      remiseMembreBureau: mouvement.remiseMembreBureau ? mouvement.remiseMembreBureau?.uid : null
    });
    if (mouvement.typeReglement === TypeReglement.PORTE_FEUILLE) {
      const newMontant = (mouvement.licencie["porte-feuille"] - mouvement.montant);
      await db.updateLicencie(mouvement.licencie?.uid, {
        "porte-feuille": newMontant
      });
      /*await db.addMouvement(
        {
          panier: mouvement.panier,
          typeMouvement: TypeMouvement.DEPOT,
          motif: "Retrait",
          typeReglement: mouvement.typeReglement,
          montant: -mouvement.montant,
          status: StatusMouvement.CLOTURE,
          remiseMembreBureau: null,
          licencie: mouvement.licencie?.uid,
          createur: user?.uid
        }, true);*/
    }
    handleCloseConfirmation();
  };

  const onNotPaiementMouvement = async (mouvement) => {
    const lic = mouvement.licencie;
    if (lic == null) return;
    await db.updateMouvement(mouvement?.uid, {
      status: StatusMouvement.ATTENTE_PAIEMENT,
    });

    if (mouvement.typeReglement === TypeReglement.PORTE_FEUILLE) {
      const newMontant = (lic["porte-feuille"] + mouvement.montant);
      await db.updateLicencie(mouvement.licencie?.uid, {
        "porte-feuille": newMontant
      });
    }

    handleCloseConfirmation();
  };

  const onAlreadyDistribMouvement = async (mouvement) => {
    if (mouvement.licencie === null) return;
    enleveStock(mouvement, TypeMouvement.ACHAT);

    await db.updateMouvement(mouvement?.uid, {
      distribuePar: user?.uid,
      alreadyDistrib: true
    });

    handleCloseConfirmation();
  };
  const onNotAlreadyDistribMouvement = async (mouvement) => {
    if (mouvement.licencie === null) return;
    ajouteStock(mouvement);
    await db.updateMouvement(mouvement?.uid, {
      distribuePar: null,
      alreadyDistrib: false
    });
    handleCloseConfirmation();
  };

  const onAlreadyRetirerCoffre = async (mouvement) => {
    if (mouvement.licencie === null) return;
    await db.updateMouvement(mouvement?.uid, {
      alreadyRetirerCoffre: true
    });

    handleCloseConfirmation();
  };
  const onNotAlreadyRetirerCoffre = async (mouvement) => {
    if (mouvement.licencie === null) return;
    await db.updateMouvement(mouvement?.uid, {
      alreadyRetirerCoffre: false
    });
    handleCloseConfirmation();
  };

  const onDeposeMouvement = async (mouvement, dansCoffre) => {
    if (mouvement.licencie === null) return;
    await db.updateMouvement(mouvement?.uid, {
      status: StatusMouvement.ATTENTE_VALIDATION,
      dansCoffre: dansCoffre ? Timestamp.now() : null,
      remisTresorier: dansCoffre ? null : Timestamp.now(),
    });
    handleCloseConfirmation();
  };

  const onNotDeposeMouvement = async (mouvement) => {
    if (mouvement.licencie === null) return;
    await db.updateMouvement(mouvement?.uid, {
      status: StatusMouvement.ATTENTE_DEPOT,
      dansCoffre: null,
      remisTresorier: null,
    });
    handleCloseConfirmation();
  };

  const onDistribueMouvement = async (mouvement) => {
    if (mouvement.licencie === null) return;

    await enleveStock(mouvement, TypeMouvement.ACHAT);

    await db.updateMouvement(mouvement?.uid, {
      status: StatusMouvement.CLOTURE,
      distribuePar: user?.uid
    });

    //on déplace le mouvement sur la personne
    mouvement.status = StatusMouvement.CLOTURE;
    mouvement.createur = mouvement.createur?.uid;
    mouvement.licencie = mouvement.licencie?.uid;
    mouvement.remiseMembreBureau = mouvement.remiseMembreBureau ? mouvement.remiseMembreBureau?.uid : null;
    mouvement.distribuePar = user?.uid;
    db.addMouvement(mouvement, true);
    db.removeMouvement(mouvement?.uid);
    handleCloseConfirmation();
  };

  const enleveStock = async (mouvement, motif) => {
    const panier = mouvement.panier;
    const manqueStock = [];
    let articlesMouv = [];
    await Promise.all(panier.map(async articlePanier => {
      const indOf = articles.findIndex(a => a?.uid === articlePanier?.uid);
      if (indOf > -1) {
        const article = articles[indOf];
        if (article.withTaille) {
          const curQteObj = JSON.parse(article["qteTaille" + articlePanier["tailleGenre"]]);
          const curQte = curQteObj["_" + articlePanier["taille"]];
          if (articlePanier.nb > curQte) {
            manqueStock.push({ article, manque: articlePanier.nb - curQte });
          } else {
            curQteObj["_" + articlePanier["taille"]] = curQte - articlePanier.nb;
            articlesMouv.splice(0, 0, {
              article,
              newQteObj: curQteObj,
              withTaille: true,
              tailleGenre: articlePanier["tailleGenre"],
              taille: articlePanier["taille"],
              mouvementStock: {
                "typeMouvement": TypeMouvement.ACHAT,
                "qte": -articlePanier.nb,
                "modifiedBy": null,
                "licencie": mouvement.licencie,
                "date": mouvement.date,
                "motif": motif
              }
            })
          }

        } else {
          if (articlePanier.nb > article.qte) {
            manqueStock.push({ article, manque: articlePanier.nb - article.qte });
          } else {
            articlesMouv.splice(0, 0, {
              article,
              newQte: article.qte - articlePanier.nb,
              mouvementStock: {
                "typeMouvement": TypeMouvement.ACHAT,
                "qte": -articlePanier.nb,
                "modifiedBy": null,
                "licencie": mouvement.licencie,
                "date": mouvement.date,
                "motif": motif
              }
            })
          }
        }

      }
      return articlePanier;
    }));

    if (manqueStock.length > 0) {
      handleShowAlert(
        "Pas assez de stock sur certains articles",
        <div>
          {manqueStock.map(o => {
            return <span>{o.title} : {o.manque}</span>
          })}
        </div>,
        "warning"
      );
      return;
    }

    await Promise.all(articlesMouv.map(async o => {
      o.mouvementStock["modifiedBy"] = o.mouvementStock["modifiedBy"] ? o.mouvementStock["modifiedBy"].uid : null;
      o.mouvementStock["licencie"] = o.mouvementStock["licencie"] ? o.mouvementStock["licencie"].uid : null;
      o.article.mouvementStock.splice(0, 0, o.mouvementStock);
      if (o.withTaille) {
        const objUpdate = {};
        objUpdate["qteTaille" + o.tailleGenre] = JSON.stringify(o.newQteObj);
        objUpdate["mouvementStock"] = o.article.mouvementStock;
        await db.updateArticle(o.article?.uid, objUpdate);
      } else {
        await db.updateArticle(o.article?.uid, {
          qte: o.newQte,
          mouvementStock: o.article.mouvementStock
        });
      }
      return o;
    }));
  }

  const ajouteStock = async (mouvement) => {
    const panier = mouvement.panier;
    panier.map(async articlePanier => {
      const indOf = articles.findIndex(a => a?.uid === articlePanier?.uid);
      if (indOf > -1) {
        const article = articles[indOf];
        if (article.withTaille) {

          const curQteObj = JSON.parse(article["qteTaille" + articlePanier["tailleGenre"]]);
          const curQte = curQteObj["_" + articlePanier["taille"]];
          curQteObj["_" + articlePanier["taille"]] = curQte + articlePanier.nb;

          const mouvementStock = article.mouvementStock;
          mouvementStock.splice(0, 0, {
            "typeMouvement": TypeMouvement.DEPOT,
            "motif": TypeMotifStock.RETOUR_COMMANDE,
            "qte": articlePanier.nb,
            "modifiedBy": null,
            "licencie": mouvement.licencie.uid,
            "date": mouvement.date
          });
          const objUpdate = {};
          objUpdate["qteTaille" + articlePanier["tailleGenre"]] = JSON.stringify(curQteObj);
          objUpdate["mouvementStock"] = mouvementStock;
          await db.updateArticle(article?.uid, objUpdate);

        } else {
          const mouvementStock = article.mouvementStock;
          mouvementStock.splice(0, 0, {
            "typeMouvement": TypeMouvement.DEPOT,
            "motif": TypeMotifStock.RETOUR_COMMANDE,
            "qte": articlePanier.nb,
            "modifiedBy": null,
            "licencie": mouvement.licencie.uid,
            "date": mouvement.date
          });
          await db.updateArticle(article?.uid, {
            qte: article.qte + articlePanier.nb,
            mouvementStock
          });
        }
      }
      return articlePanier;
    })
  }

  const onNotDistribueMouvement = async (mouvement) => {
    if (mouvement.licencie === null) return;

    await ajouteStock(mouvement);

    await db.updateMouvement(mouvement?.uid, {
      status: StatusMouvement.ATTENTE_DISTRIBUTION,
      distribuePar: null
    }, mouvement.licencie?.uid, true);
    //on déplace le mouvement sur le global
    mouvement.status = StatusMouvement.ATTENTE_DISTRIBUTION;
    mouvement.createur = mouvement.createur?.uid;
    mouvement.licencie = mouvement.licencie?.uid;
    mouvement.remiseMembreBureau = mouvement.remiseMembreBureau ? mouvement.remiseMembreBureau?.uid : null;
    mouvement.distribuePar = null;
    db.addMouvement(mouvement);
    db.removeMouvement(mouvement?.uid, mouvement.licencie, true);
    handleCloseConfirmation();
  };

  const handleDeleteMouvement = async (mouvement) => {
    if (mouvement.licencie === null) return;
    try {
      await db.removeMouvement(mouvement?.uid);
      onValid();
      handleCloseConfirmation();
    } catch (error) {
      console.log("Error delete mouvement", error);
    }
  };

  const mouvementsAchatToPaiement = mouvements.filter(m => m.typeMouvement === TypeMouvement.ACHAT && m.status === StatusMouvement.ATTENTE_PAIEMENT);
  const mouvementsAchatToDepose = mouvements.filter(m => m.typeMouvement === TypeMouvement.ACHAT && m.status === StatusMouvement.ATTENTE_DEPOT);
  const mouvementsAchatToValid = mouvements.filter(m => m.typeMouvement === TypeMouvement.ACHAT && m.status === StatusMouvement.ATTENTE_VALIDATION);
  const mouvementsAchatToDistrib = mouvements.filter(m => m.typeMouvement === TypeMouvement.ACHAT && m.status === StatusMouvement.ATTENTE_DISTRIBUTION);
  const mouvementsAchatOther = mouvementsAllUser
    .filter(m => m.typeMouvement === TypeMouvement.ACHAT)
    .sort((ent1, ent2) => ent1.date.toDate() > ent2.date.toDate() ? -1 : 1
    );

  const mouvementsDepotToDepose = mouvements.filter(m => m.typeMouvement === TypeMouvement.DEPOT && m.status === StatusMouvement.ATTENTE_DEPOT);
  const mouvementsDepotToValid = mouvements.filter(m => m.typeMouvement === TypeMouvement.DEPOT && m.status === StatusMouvement.ATTENTE_VALIDATION);
  const mouvementsDepotOther = mouvementsAllUser
    .filter(m => m.typeMouvement === TypeMouvement.DEPOT)
    .sort((ent1, ent2) => ent1.date.toDate() > ent2.date.toDate() ? -1 : 1
    );

  useEffect(() => {
    //const unsubscribeMouvements = [];
    const retour = [];
    //const q = query(collection(db.db, "clubs/badlevier/licenciés"));
    //const unsubscribe = onSnapshot(q, (querySnapshot) => {
    //querySnapshot.forEach(function (doc) {
    //const currentUser = doc.data();
    const q1 = query(collection(db.db, "clubs/badlevier/mouvements"));
    const unsubscribe = onSnapshot(q1, async (querySnapshot2) => {
      await Promise.all(
        querySnapshot2.docs.map(async (doc) => {
          const mouvement = doc.data();
          mouvement["licencie"] = await getLicencie(mouvement.licencie);
          if (mouvement["licencie"] === null) return;
          mouvement["createur"] = await getLicencie(mouvement.createur);
          mouvement["remiseMembreBureau"] = await getLicencie(mouvement.remiseMembreBureau);
          mouvement["distribuePar"] = mouvement.distribuePar ? await getLicencie(mouvement.distribuePar) : null;
          const mouvementFindIndex = retour.findIndex(mouv => mouv?.uid === mouvement?.uid);
          if (mouvementFindIndex > -1) {
            retour[mouvementFindIndex] = mouvement;
          } else {
            retour.push(mouvement);
          }
        }));
      setMouvements([...retour]);
    });
    //unsubscribeMouvements.push(unsubscribeMouvement);
    //});
    // });

    const unsubscribeMouvements = [];
    const retour2 = [];
    const q = query(collection(db.db, "clubs/badlevier/licenciés"));
    const unsubscribe2 = onSnapshot(q, async (querySnapshot) => {
      await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const currentUser = doc.data();
          const q2 = query(collection(db.db, "clubs/badlevier/licenciés/" + currentUser?.uid + "/mouvements"));
          const unsubscribeMouvement = onSnapshot(q2, async (querySnapshot2) => {
            await Promise.all(
              querySnapshot2.docs.map(async (doc) => {
                const mouvement = doc.data();
                mouvement["licencie"] = await getLicencie(mouvement.licencie);
                if (mouvement["licencie"] === null) return;
                mouvement["createur"] = await getLicencie(mouvement.createur);
                mouvement["remiseMembreBureau"] = await getLicencie(mouvement.remiseMembreBureau);
                mouvement["distribuePar"] = mouvement.distribuePar ? await getLicencie(mouvement.distribuePar) : null;
                const mouvementFindIndex = retour2.findIndex(mouv => mouv?.uid === mouvement?.uid);
                if (mouvementFindIndex > -1) {
                  retour2[mouvementFindIndex] = mouvement;
                } else {
                  retour2.push(mouvement);
                }
              }));
            setMouvementsAllUser([...retour2]);

          });
          unsubscribeMouvements.push(unsubscribeMouvement);
        }));
    });

    const q3 = query(collection(db.db, "clubs/badlevier/articles"));
    const unsubscribeArticle = onSnapshot(q3, async (querySnapshotArticle) => {
      const retour = [];
      await Promise.all(
        querySnapshotArticle.docs.map(async (doc) => {
          const article = doc.data();
          const articleFindIndex = retour.findIndex(cre => cre?.uid === article?.uid);
          if (articleFindIndex > -1) {
            retour[articleFindIndex] = article;
          } else {
            retour.push(article);
          }
        })
      );
      setArticles([...retour]);
    });

    const q4 = query(doc(db.db, "clubs/badlevier"));
    const unsubscribeBureau = onSnapshot(q4, async (doc) => {
      const badlevier = doc.data();
      if (badlevier) {
        const membres = [];
        for (const membreUid of badlevier["bureau"]["membres"]) {
          membres.push(await getLicencie(membreUid));
        }
        membres.push(await getLicencie(badlevier["bureau"]["président"]));
        membres.push(await getLicencie(badlevier["bureau"]["vice-président"]));
        membres.push(await getLicencie(badlevier["bureau"]["trésorier"]));
        membres.push(await getLicencie(badlevier["bureau"]["secrétaire"]));
        setMembresBureau(membres.filter(lic => lic != null));
      }
    });

    return () => {
      unsubscribe();
      unsubscribe2();
      unsubscribeMouvements.forEach(un => un());
      unsubscribeArticle();
      unsubscribeBureau();
    };
  }, [db, getLicencie]);

  const badgeAchatCompt = mouvementsAchatToDepose.length + mouvementsAchatToValid.length + mouvementsAchatToDistrib.length;
  const badgeDepotCompt = mouvementsDepotToDepose.length + mouvementsDepotToValid.length;

  return (
    <Tabs
      defaultActiveKey="commandes"
      id="noanim-tab-example"
      className="mb-3"
      fill
    >
      <Tab eventKey="commandes"
        title=<div><
          FontAwesomeIcon icon={faShoppingCart} style={{ fontSize: "1em", marginRight: "5px" }} />
          <span style={{
            marginRight: "5px"
          }}>Commandes
          </span>
          {badgeAchatCompt > 0 && <Badge bg="warning" style={{ color: "black", border: "1px solid gray", padding: "3px 5px" }}>{badgeAchatCompt}</Badge>}

        </div>
      >
        <Container>
          <div
            className="section"
            style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
          >
            <Button style={{ width: "100%" }} className="btn btn-custom2" onClick={ajoutCommande}>
              Ajouter une commande
            </Button>
          </div>
          <Container
            className="tresorerieListStatus"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <h5 className="achatToPaiement">À payer</h5>
            {mouvementsAchatToPaiement.length === 0 ? (
              <Container style={{ marginBottom: "10px" }}><span>Rien à payer</span></Container>) : (
              <>
                <Accordion style={{ width: "100%", marginBottom: "10px" }}>
                  {mouvementsAchatToPaiement.map((mouvement, index) => {
                    const lic = mouvement.licencie;
                    const createur = mouvement.createur;
                    const isCreateur = user && mouvement.createur === user?.uid;
                    const remiseMembre = mouvement.remiseMembreBureau;
                    const canDelete = isAdmin || isCreateur;
                    const porteFeuille = lic ? lic["porte-feuille"] : 0;
                    let panier = mouvement.panier;
                    const manqueRemiseMembreBureau = mouvement.typeReglement !== TypeReglement.VIREMENT_BANCAIRE && mouvement.typeReglement !== TypeReglement.PORTE_FEUILLE && mouvement.remiseMembreBureau === null;
                    let soldePorteFeuilleInsuffisant = mouvement.typeReglement === TypeReglement.PORTE_FEUILLE && porteFeuille !== null && parseFloat(mouvement.montant) > parseFloat(porteFeuille);

                    const changeTypeReglement = (currentType) => {
                      mouvement.typeReglement = currentType;
                      const indOf = mouvements.findIndex(m => m?.uid === mouvement?.uid);
                      if (indOf > -1) {
                        mouvements[indOf] = mouvement;
                      }
                      setMouvements([...mouvements]);
                    }

                    const changeRemiseMembre = (membre) => {
                      mouvement.remiseMembreBureau = membre;
                      const indOf = mouvements.findIndex(m => m?.uid === mouvement?.uid);
                      if (indOf > -1) {
                        mouvements[indOf] = mouvement;
                      }
                      setMouvements([...mouvements]);
                    }
                    return (
                      <Accordion.Item eventKey={index} key={index}  >
                        <Accordion.Header >
                          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                            <div style={{ display: "flex", flex: "1 1 auto", justifyContent: "space-between", alignItems: "center" }}>
                              <div style={{ display: "flex", flex: "1 1 auto", flexDirection: "column", justifyContent: "space-between" }}>
                                <b><span>{mouvement.motif}</span></b>
                                <span style={{ fontSize: "0.8em" }}>{lic.firstName + " " + lic.lastName}</span>
                              </div>
                              <span style={{ minWidth: "70px", fontWeight: "bold", textAlign: "right" }}>{(mouvement.montant > 0 ? "+" : "") + mouvement.montant}€</span>
                            </div>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          {panier && (
                            <>
                              <div style={{
                                padding: "5px 10px",
                                width: "100%",
                                backgroundColor: "rgb(226, 240, 226)"
                              }}><b>Récapitulatif</b></div>

                              {panier && panier.map((article, index) => {
                                return (
                                  <div
                                    key={index}
                                    style={{
                                      fontSize: "1em",
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "space-between",
                                      width: "100%",
                                      alignItems: "flex-start",
                                      padding: "5px 5px",
                                      backgroundColor: "rgb(226, 240, 226)"
                                    }}
                                  >
                                    <div
                                      key={index}
                                      style={{
                                        fontSize: "0.8em",
                                        flex: "1 1 auto",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "100%",
                                        alignItems: "center",
                                        padding: "0px 5px",
                                        borderBottom: "1px solid gray"
                                      }}
                                    >
                                      <span style={{ minWidth: "30px", textAlign: "left", marginRight: "5px" }}>
                                        x {article["nb"]}
                                      </span>
                                      <div style={{ display: "flex", flexDirection: "column" }}>

                                        <span style={{
                                          flex: "1 1 auto",
                                        }}>
                                          {article.title} {article.withTaille && " - " + article.tailleGenre + " - Taille " + article.taille}
                                        </span>
                                        {article["flocageAvant"] !== "" &&
                                          <span style={{ minWidth: "30px", textAlign: "left", marginRight: "5px" }}>
                                            <span>Flocage avant : </span>
                                            <span style={{ minWidth: "30px", textAlign: "left", marginRight: "5px", backgroundColor: "yellow" }}>
                                              {article["flocageAvant"]}
                                            </span>
                                          </span>
                                        }
                                      </div>
                                      <span style={{ minWidth: "70px", textAlign: "right" }}>
                                        {article.prix * article["nb"]} €
                                      </span>

                                    </div>
                                  </div>
                                );
                              })
                              }
                              <div style={{ textAlign: "right", width: "100%", padding: "5px 10px", backgroundColor: "rgb(226, 240, 226)" }} className="spanKeyValue" >
                                Total   <b style={{ marginLeft: "10px" }}>{mouvement.montant}€ </b>
                              </div>
                            </>
                          )}
                          <Form
                            style={{ display: "flex", flexDirection: "column", width: "100%" }}
                          >
                            <div style={{ display: "flex", alignItems: "flex-start" }}>
                              <div style={{ display: "flex", flexDirection: "column", flex: "1 1 auto" }}>
                                <span style={{ marginBottom: "5px", marginLeft: "5px", color: "green" }}>Mode de réglement</span>
                                <ButtonGroup vertical style={{ marginBottom: "10px", flex: "1 1 auto" }}>
                                  <ModeReglement
                                    mouvement={mouvement}
                                    porteFeuille={porteFeuille}
                                    changeTypeReglement={changeTypeReglement}>
                                  </ModeReglement>
                                </ButtonGroup>
                              </div>
                              {mouvement.typeReglement !== TypeReglement.VIREMENT_BANCAIRE && mouvement.typeReglement !== TypeReglement.PORTE_FEUILLE &&
                                <div style={{ display: "flex", flexDirection: "column", marginLeft: "5px" }}>
                                  <span style={{ marginBottom: "5px", marginLeft: "5px", color: "green" }}>Remis à</span>
                                  <ButtonGroup vertical style={{ marginBottom: "10px" }}>
                                    <RemiseMembre
                                      membresBureau={membresBureau}
                                      remiseMembre={mouvement.remiseMembreBureau}
                                      changeRemiseMembre={changeRemiseMembre}>
                                    </RemiseMembre>
                                  </ButtonGroup>
                                </div>
                              }
                            </div>

                            {manqueRemiseMembreBureau && <span style={{ color: "darkred", textAlign: "right", marginRight: "5px" }}>Veuillez sélectionner la personne à qui vous avez remis l'argent</span>}

                            <Button
                              style={{ width: "100%" }}
                              className="btn btn-custom2"
                              disabled={manqueRemiseMembreBureau || soldePorteFeuilleInsuffisant}
                              onClick={() => {
                                handleShowConfirmation(
                                  "",
                                  mouvement.typeReglement === TypeReglement.PORTE_FEUILLE ?
                                    "Vous confirmez le paiement ?" :
                                    "Vous confirmez avoir effectué le paiement ?",
                                  "success",
                                  "C'est payé",
                                  "Annuler",
                                  null,
                                  () => onValidPaiement(mouvement)
                                );
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faCheck}
                                style={{ fontSize: "1em", marginRight: "5px", color: "white" }}
                              />
                              Payer
                            </Button>
                          </Form>
                          <label className="labelKeyValue" >
                            Créé le
                            <span>{getDateTraining(mouvement.date)}</span>
                          </label>
                          <label className="labelKeyValue" >
                            Créé par
                            <span>{(user && mouvement.createur === user?.uid) ? "Vous" : createur.displayName + " " + createur.lastName}</span>
                          </label>
                          <label className="labelKeyValue" >
                            Pour
                            <span>{(user && mouvement.licencie === user?.uid) ? "Vous" : lic.displayName + "  " + lic.lastName}</span>
                          </label>
                          <label className="labelKeyValue" >
                            Mode de réglement
                            <span style={{ flex: "1 1 0", textAlign: "right" }}>{mouvement.typeReglement}</span>
                          </label>
                          <span style={{ flex: "1 1 0" }}>{mouvement.detail}</span>
                          {mouvement.typeReglement !== TypeReglement.VIREMENT_BANCAIRE && mouvement.typeReglement !== TypeReglement.PORTE_FEUILLE &&
                            <label className="labelKeyValue" >
                              Remis à
                              <span style={{ flex: "1 1 0", textAlign: "right" }}>{mouvement.remiseMembreBureau && remiseMembre.displayName}</span>
                            </label>
                          }
                          <StatusTransaction
                            typeMouvement={mouvement.typeMouvement}
                            typeReglement={mouvement.typeReglement}
                            status={mouvement.status}
                            remiseMembreBureau={mouvement.remiseMembreBureau}
                            distribuePar={mouvement.distribuePar}
                            dansCoffre={mouvement.dansCoffre}
                            alreadyDistrib={mouvement.alreadyDistrib}
                          />
                          {user && (user?.uid === mouvement.createur || isAdmin) &&
                            <div
                              style={{ width: "100%" }}
                              className="btn btn-custom5"
                              disabled={!canDelete}
                              onClick={() => {
                                if (!canDelete) return;
                                handleShowConfirmation(
                                  "",
                                  "Etes-vous sûr de vouloir annuler la commande ?",
                                  "danger",
                                  "Annuler la commande",
                                  "Annuler",
                                  null,
                                  () => handleDeleteMouvement(mouvement)
                                );
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faTimes}
                                style={{ fontSize: "1em", marginRight: "5px", color: "red" }}
                              />
                              Annuler
                            </div>
                          }
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>
              </>
            )}
            <h5 className="achatToDepose">À remettre à la trésorière</h5>
            {mouvementsAchatToDepose.length === 0 ? (
              <Container style={{ marginBottom: "10px" }}><span>Rien à remettre à la trésorière</span></Container>) : (
              <>
                <Accordion style={{ width: "100%", marginBottom: "10px" }}>
                  {mouvementsAchatToDepose.map((mouvement, index) => {
                    const lic = mouvement.licencie;
                    const createur = mouvement.createur;
                    const remiseMembre = mouvement.remiseMembreBureau;
                    const isResponsable = user && mouvement.remiseMembreBureau === user?.uid;
                    const canDepose = isAdmin || (mouvement.typeReglement !== TypeReglement.VIREMENT_BANCAIRE && user && user?.uid === mouvement.remiseMembreBureau);
                    const isCreateur = user && mouvement.createur === user?.uid;
                    const canDelete = isAdmin || isCreateur;
                    let panier = null;
                    if (mouvement.typeMouvement === TypeMouvement.ACHAT) {
                      panier = mouvement.panier;
                    }
                    return (
                      <Accordion.Item eventKey={index} key={index}  >
                        <Accordion.Header >
                          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>

                            <div style={{ backgroundColor: isResponsable ? "" : "", display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                              <div style={{ display: "flex", flex: "1 1 auto", justifyContent: "space-between", alignItems: "center" }}>
                                <div style={{ display: "flex", flex: "1 1 auto", flexDirection: "column", justifyContent: "space-between" }}>
                                  <b><span>{mouvement.motif}</span></b>
                                  <span style={{ fontSize: "0.8em" }}>{lic.firstName + " " + lic.lastName}</span>
                                </div>
                                <span style={{ minWidth: "70px", fontWeight: "bold", textAlign: "right" }}>{(mouvement.montant > 0 ? "+" : "") + mouvement.montant}€</span>
                              </div>
                            </div>

                            <div style={{ display: "flex", flexDirection: "column" }}>
                              <div
                                className="btn btn-custom2"
                                disabled={!canDepose}
                                onClick={(e) => {
                                  if (!canDepose) return;
                                  e.preventDefault();
                                  e.stopPropagation();
                                  handleShowConfirmation(
                                    "C'est remis",
                                    "L'argent a-t-il été remis à la trésorière ?",
                                    "success",
                                    "Oui",
                                    "Non",
                                    null,
                                    () => onDeposeMouvement(mouvement, false)
                                  )
                                }}
                              >
                                Remis à la trésorière
                              </div>
                              <div
                                className="btn btn-custom2"
                                disabled={!canDepose}
                                onClick={(e) => {
                                  if (!canDepose) return;
                                  e.preventDefault();
                                  e.stopPropagation();
                                  handleShowConfirmation(
                                    "C'est déposer",
                                    "L'argent a-t-il été déposé dans le coffre fort ?",
                                    "success",
                                    "Oui",
                                    "Non",
                                    null,
                                    () => onDeposeMouvement(mouvement, true)
                                  )
                                }}
                              >
                                Déposer dans le coffre
                              </div>
                            </div>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          {panier && (
                            <>
                              <div style={{
                                padding: "5px 10px",
                                width: "100%",
                                backgroundColor: "rgb(226, 240, 226)"
                              }}><b>Récapitulatif</b></div>

                              {panier && panier.map((article, index) => {
                                return (
                                  <div
                                    key={index}
                                    style={{
                                      fontSize: "1em",
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "space-between",
                                      width: "100%",
                                      alignItems: "flex-start",
                                      padding: "5px 5px",
                                      backgroundColor: "rgb(226, 240, 226)"
                                    }}
                                  >
                                    <div
                                      key={index}
                                      style={{
                                        fontSize: "0.8em",
                                        flex: "1 1 auto",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "100%",
                                        alignItems: "center",
                                        padding: "0px 5px",
                                        borderBottom: "1px solid gray"
                                      }}
                                    >
                                      <span style={{ minWidth: "30px", textAlign: "left", marginRight: "5px" }}>
                                        x {article["nb"]}
                                      </span>
                                      <span style={{
                                        flex: "1 1 auto",
                                      }}>
                                        {article.title} {article.withTaille && " - " + article.tailleGenre + " - Taille " + article.taille}
                                      </span>
                                      <span style={{ minWidth: "70px", textAlign: "right" }}>
                                        {article.prix * article["nb"]} €
                                      </span>

                                    </div>
                                  </div>
                                );
                              })
                              }
                              <div style={{ textAlign: "right", width: "100%", padding: "5px 10px", backgroundColor: "rgb(226, 240, 226)" }} className="spanKeyValue" >
                                Total   <b style={{ marginLeft: "10px" }}>{mouvement.montant}€ </b>
                              </div>
                            </>
                          )}
                          <label className="labelKeyValue" >
                            Créé le
                            <span>{getDateTraining(mouvement.date)}</span>
                          </label>
                          <label className="labelKeyValue" >
                            Créé par
                            <span>{(user && mouvement.createur === user?.uid) ? "Vous" : createur.displayName + " " + createur.lastName}</span>
                          </label>
                          <label className="labelKeyValue" >
                            Pour
                            <span>{(user && mouvement.licencie === user?.uid) ? "Vous" : lic.displayName + "  " + lic.lastName}</span>
                          </label>
                          <label className="labelKeyValue" >
                            Mode de réglement
                            <span style={{ flex: "1 1 0", textAlign: "right" }}>{mouvement.typeReglement}</span>
                          </label>
                          <span style={{ flex: "1 1 0" }}>{mouvement.detail}</span>
                          {mouvement.typeReglement !== TypeReglement.VIREMENT_BANCAIRE && mouvement.typeReglement !== TypeReglement.PORTE_FEUILLE &&
                            <label className="labelKeyValue" >
                              Remis à
                              <span style={{ flex: "1 1 0", textAlign: "right" }}>{mouvement.remiseMembreBureau && remiseMembre.displayName}</span>
                            </label>
                          }
                          <label className="labelKeyValue" >
                            Déjà distribué ?
                            <div
                              style={{}}
                              className={mouvement.alreadyDistrib ? "btn btn-custom3" : "btn btn-custom2"}
                              onClick={() => {
                                handleShowConfirmation(
                                  "",
                                  mouvement.alreadyDistrib ? "La commande n'a pas été distribuée ?" : "La commande a déjà été distribuée ?",
                                  mouvement.alreadyDistrib ? "danger" : "success",
                                  mouvement.alreadyDistrib ? "Ce n'est pas distribué" : "C'est distribué",
                                  "Annuler",
                                  null,
                                  mouvement.alreadyDistrib ? () => onNotAlreadyDistribMouvement(mouvement) : () => onAlreadyDistribMouvement(mouvement)
                                );
                              }}
                            >
                              {mouvement.alreadyDistrib &&
                                <FontAwesomeIcon
                                  icon={faCheck}
                                  style={{ fontSize: "1em", marginRight: "5px", color: "green" }}
                                />
                              }
                              {mouvement.alreadyDistrib ? "Distribué" : "Distribuer"}
                            </div>
                          </label>
                          <StatusTransaction
                            typeMouvement={mouvement.typeMouvement}
                            typeReglement={mouvement.typeReglement}
                            status={mouvement.status}
                            remiseMembreBureau={mouvement.remiseMembreBureau}
                            distribuePar={mouvement.distribuePar}
                            dansCoffre={mouvement.dansCoffre}
                            alreadyDistrib={mouvement.alreadyDistrib}
                          />
                          {
                            mouvement.status === StatusMouvement.ATTENTE_DEPOT && user && (user?.uid === mouvement.createur || isAdmin) &&
                            <div
                              style={{ width: "100%" }}
                              className="btn btn-custom5"
                              disabled={!canDelete}
                              onClick={() => {
                                if (!canDelete) return;
                                handleShowConfirmation(
                                  "",
                                  "L'argent n'a pas été remis à " + mouvement.remiseMembreBureau.displayName + " ?",
                                  "danger",
                                  "Pas remis",
                                  "Annuler",
                                  null,
                                  () => onNotPaiementMouvement(mouvement)
                                );
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faTimes}
                                style={{ fontSize: "1em", marginRight: "5px", color: "red" }}
                              />
                              Pas remis ?
                            </div>
                          }
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>
              </>
            )}
            <h5 className="achatToValid">À valider</h5>
            {mouvementsAchatToValid.length === 0 ? (
              <Container style={{ marginBottom: "10px" }}><span>Rien à valider</span></Container>) : (
              <>
                <Accordion style={{ width: "100%", marginBottom: "10px" }}>
                  {mouvementsAchatToValid.map((mouvement, index) => {
                    const lic = mouvement.licencie;
                    const createur = mouvement.createur;
                    const remiseMembre = mouvement.remiseMembreBureau;
                    const canValid = isAdmin || isTresorier;
                    const canNotDepose = isAdmin || (mouvement.typeReglement !== TypeReglement.VIREMENT_BANCAIRE && user && user?.uid === mouvement.remiseMembreBureau);
                    let panier = null;
                    if (mouvement.typeMouvement === TypeMouvement.ACHAT) {
                      panier = mouvement.panier;
                    }
                    return (
                      <Accordion.Item eventKey={index} key={index}>
                        <Accordion.Header>
                          <div key={index} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }} >
                            <div style={{ display: "flex", flex: "1 1 auto", justifyContent: "space-between", alignItems: "center" }}>
                              <div style={{ display: "flex", flex: "1 1 auto", flexDirection: "column", justifyContent: "space-between" }}>
                                <b><span>{mouvement.motif}</span></b>
                                <span style={{ fontSize: "0.8em" }}>{lic.firstName + " " + lic.lastName}</span>
                              </div>
                              <span style={{ minWidth: "70px", fontWeight: "bold", textAlign: "right" }}>{(mouvement.montant > 0 ? "+" : "") + mouvement.montant}€</span>
                            </div>

                            <div
                              className="btn btn-custom2"
                              disabled={!canValid}
                              onClick={(e) => {
                                if (!canValid) return;
                                e.preventDefault();
                                e.stopPropagation();
                                handleShowConfirmation(
                                  "C'est encaissé ?",
                                  "L'argent apparaît bien sur le compte ?",
                                  "success",
                                  "Valider",
                                  "Annuler",
                                  null,
                                  () => onValidMouvement(mouvement)
                                )
                              }}
                            >
                              Valider
                            </div>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          {panier && (
                            <>
                              <div style={{
                                padding: "5px 10px",
                                width: "100%",
                                backgroundColor: "rgb(226, 240, 226)"
                              }}><b>Récapitulatif</b></div>

                              {panier && panier.map((article, index) => {
                                return (
                                  <div
                                    key={index}
                                    style={{
                                      fontSize: "1em",
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "space-between",
                                      width: "100%",
                                      alignItems: "flex-start",
                                      padding: "5px 5px",
                                      backgroundColor: "rgb(226, 240, 226)"
                                    }}
                                  >
                                    <div
                                      key={index}
                                      style={{
                                        fontSize: "0.8em",
                                        flex: "1 1 auto",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "100%",
                                        alignItems: "center",
                                        padding: "0px 5px",
                                        borderBottom: "1px solid gray"
                                      }}
                                    >
                                      <span style={{ minWidth: "30px", textAlign: "left", marginRight: "5px" }}>
                                        x {article["nb"]}
                                      </span>
                                      <span style={{
                                        flex: "1 1 auto",
                                      }}>
                                        {article.title} {article.withTaille && " - " + article.tailleGenre + " - Taille " + article.taille}
                                      </span>
                                      <span style={{ minWidth: "70px", textAlign: "right" }}>
                                        {article.prix * article["nb"]} €
                                      </span>

                                    </div>
                                  </div>
                                );
                              })
                              }
                              <div style={{ textAlign: "right", width: "100%", padding: "5px 10px", backgroundColor: "rgb(226, 240, 226)" }} className="spanKeyValue" >
                                Total   <b style={{ marginLeft: "10px" }}>{mouvement.montant}€ </b>
                              </div>
                            </>
                          )}
                          <label className="labelKeyValue" >
                            Créé le
                            <span>{getDateTraining(mouvement.date)}</span>
                          </label>
                          <label className="labelKeyValue" >
                            Créé par
                            <span>{(user && mouvement.createur === user?.uid) ? "Vous" : createur.displayName + "  " + createur.lastName}</span>
                          </label>
                          <label className="labelKeyValue" >
                            Pour
                            <span>{(user && mouvement.licencie === user?.uid) ? "Vous" : lic.displayName + "  " + lic.lastName}</span>
                          </label>
                          <label className="labelKeyValue" >
                            Mode de réglement
                            <span style={{ flex: "1 1 0", textAlign: "right" }}>{mouvement.typeReglement}</span>
                          </label>
                          <span style={{ flex: "1 1 0" }}>{mouvement.detail}</span>
                          {mouvement.typeReglement !== TypeReglement.VIREMENT_BANCAIRE && mouvement.typeReglement !== TypeReglement.PORTE_FEUILLE &&
                            <label className="labelKeyValue" >
                              Remis à
                              <span style={{ flex: "1 1 0", textAlign: "right" }}>{mouvement.remiseMembreBureau && remiseMembre.displayName}</span>
                            </label>
                          }
                          {mouvement.dansCoffre &&
                            <label className="labelKeyValue" >
                              Déposé dans le coffre le
                              <span>{getDateTraining(mouvement.dansCoffre)}</span>
                            </label>
                          }
                          {(mouvement.dansCoffre && (db.isTresorier || db.isAdmin)) &&
                            <label className="labelKeyValue" >
                              Retiré du coffre ?
                              <div
                                style={{}}
                                className={mouvement.alreadyRetirerCoffre ? "btn btn-custom3" : "btn btn-custom2"}
                                onClick={() => {
                                  handleShowConfirmation(
                                    "",
                                    mouvement.alreadyRetirerCoffre ? "Ce n'est pas été retiré du coffre ?" : "C'est retiré du coffre ?",
                                    mouvement.alreadyRetirerCoffre ? "danger" : "success",
                                    mouvement.alreadyRetirerCoffre ? "Ce n'est pas retiré" : "C'est retiré",
                                    "Annuler",
                                    null,
                                    mouvement.alreadyRetirerCoffre ? () => onNotAlreadyRetirerCoffre(mouvement) : () => onAlreadyRetirerCoffre(mouvement)
                                  );
                                }}
                              >
                                {mouvement.alreadyRetirerCoffre &&
                                  <FontAwesomeIcon
                                    icon={faCheck}
                                    style={{ fontSize: "1em", marginRight: "5px", color: "green" }}
                                  />
                                }
                                {mouvement.alreadyDistrib ? "Retiré" : "Retirer"}
                              </div>
                            </label>
                          }
                          {mouvement.remisTresorier &&
                            <label className="labelKeyValue" >
                              Remis à la trésorière le
                              <span>{getDateTraining(mouvement.remisTresorier)}</span>
                            </label>
                          }
                          <label className="labelKeyValue" >
                            Déjà distribué ?
                            <div
                              style={{}}
                              className={mouvement.alreadyDistrib ? "btn btn-custom3" : "btn btn-custom2"}
                              onClick={() => {
                                handleShowConfirmation(
                                  "",
                                  mouvement.alreadyDistrib ? "La commande n'a pas été distribuée ?" : "La commande a déjà été distribuée ?",
                                  mouvement.alreadyDistrib ? "danger" : "success",
                                  mouvement.alreadyDistrib ? "Ce n'est pas distribué" : "C'est distribué",
                                  "Annuler",
                                  null,
                                  mouvement.alreadyDistrib ? () => onNotAlreadyDistribMouvement(mouvement) : () => onAlreadyDistribMouvement(mouvement)
                                );
                              }}
                            >
                              {mouvement.alreadyDistrib &&
                                <FontAwesomeIcon
                                  icon={faCheck}
                                  style={{ fontSize: "1em", marginRight: "5px", color: "green" }}
                                />
                              }
                              {mouvement.alreadyDistrib ? "Distribué" : "Distribuer"}
                            </div>
                          </label>

                          <StatusTransaction
                            typeMouvement={mouvement.typeMouvement}
                            typeReglement={mouvement.typeReglement}
                            status={mouvement.status}
                            remiseMembreBureau={mouvement.remiseMembreBureau}
                            distribuePar={mouvement.distribuePar}
                            dansCoffre={mouvement.dansCoffre}
                            alreadyDistrib={mouvement.alreadyDistrib}
                          />
                          {
                            mouvement.status === StatusMouvement.ATTENTE_VALIDATION && user && (user?.uid === mouvement.createur || isAdmin) &&
                            <div
                              style={{ width: "100%" }}
                              className="btn btn-custom5"
                              disabled={!canNotDepose}
                              onClick={() => {
                                if (!canNotDepose) return;
                                handleShowConfirmation(
                                  "",
                                  mouvement.typeReglement === TypeReglement.VIREMENT_BANCAIRE || mouvement.typeReglement === TypeReglement.PORTE_FEUILLE ? "La commande n'a pas été payé ?" : (mouvement.dansCoffre ? "L'argent n'est pas dans le coffre ?" : "L'argent n'a pas été remis à la trésorière ?"),
                                  "danger",
                                  mouvement.typeReglement === TypeReglement.VIREMENT_BANCAIRE || mouvement.typeReglement === TypeReglement.PORTE_FEUILLE ? "Pas payé" : (mouvement.dansCoffre ? "Pas déposé dans le coffre" : "Pas remis à la trésorière"),
                                  "Annuler",
                                  null,
                                  mouvement.typeReglement === TypeReglement.VIREMENT_BANCAIRE || mouvement.typeReglement === TypeReglement.PORTE_FEUILLE ? () => onNotPaiementMouvement(mouvement) : () => onNotDeposeMouvement(mouvement)
                                );
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faTimes}
                                style={{ fontSize: "1em", marginRight: "5px", color: "red" }}
                              />
                              {mouvement.typeReglement === TypeReglement.VIREMENT_BANCAIRE || mouvement.typeReglement === TypeReglement.PORTE_FEUILLE ? "Pas payé ?" : (mouvement.dansCoffre ? "Pas déposé ?" : "Pas remis ?")}
                            </div>
                          }
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>
              </>
            )}
            <h5 className="achatToDistrib">A distribuer</h5>
            {mouvementsAchatToDistrib.length === 0 ? (
              <Container style={{ marginBottom: "10px" }}><span>Rien à distribuer</span></Container>) : (
              <>
                <Accordion style={{ width: "100%", marginBottom: "10px" }}>
                  {
                    mouvementsAchatToDistrib.map((mouvement, index) => {
                      const lic = mouvement.licencie;
                      const createur = mouvement.createur;
                      const remiseMembre = mouvement.remiseMembreBureau;
                      const canDistrib = true; //tout le monde
                      let panier = null;
                      if (mouvement.typeMouvement === TypeMouvement.ACHAT) {
                        panier = mouvement.panier;
                      }
                      return (
                        <Accordion.Item eventKey={index} key={index}>
                          <Accordion.Header>
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginRight: "10px"
                              }}
                            >
                              <div style={{ display: "flex", flex: "1 1 auto", justifyContent: "space-between", alignItems: "center" }}>
                                <div style={{ display: "flex", flex: "1 1 auto", flexDirection: "column", justifyContent: "space-between" }}>
                                  <b><span>{mouvement.motif}</span></b>
                                  <span style={{ fontSize: "0.8em" }}>{lic.firstName + " " + lic.lastName}</span>
                                </div>
                              </div>
                              <span style={{ minWidth: "70px", fontWeight: "bold", textAlign: "right" }}>{(mouvement.montant > 0 ? "+" : "") + mouvement.montant}€</span>
                              <div
                                className="btn btn-custom2"
                                disabled={!canDistrib}
                                onClick={(e) => {
                                  if (!canDistrib) return;
                                  e.preventDefault();
                                  e.stopPropagation();
                                  handleShowConfirmation(
                                    "C'est distribué",
                                    "Avez-vous donné la commande ?",
                                    "success",
                                    "Distribué",
                                    "Annuler",
                                    null,
                                    () => onDistribueMouvement(mouvement)
                                  )
                                }}
                              >
                                Distribuer
                              </div>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            {panier && (
                              <>
                                <div style={{
                                  padding: "5px 10px",
                                  width: "100%",
                                  backgroundColor: "rgb(226, 240, 226)"
                                }}><b>Récapitulatif</b></div>

                                {panier && panier.map((article, index) => {
                                  return (
                                    <div
                                      key={index}
                                      style={{
                                        fontSize: "1em",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                        width: "100%",
                                        alignItems: "flex-start",
                                        padding: "5px 5px",
                                        backgroundColor: "rgb(226, 240, 226)"
                                      }}
                                    >
                                      <div
                                        key={index}
                                        style={{
                                          fontSize: "0.8em",
                                          flex: "1 1 auto",
                                          display: "flex",
                                          justifyContent: "space-between",
                                          width: "100%",
                                          alignItems: "center",
                                          padding: "0px 5px",
                                          borderBottom: "1px solid gray"
                                        }}
                                      >
                                        <span style={{ minWidth: "30px", textAlign: "left", marginRight: "5px" }}>
                                          x {article["nb"]}
                                        </span>
                                        <span style={{
                                          flex: "1 1 auto",
                                        }}>
                                          {article.title} {article.withTaille && " - " + article.tailleGenre + " - Taille " + article.taille}
                                        </span>
                                        <span style={{ minWidth: "70px", textAlign: "right" }}>
                                          {article.prix * article["nb"]} €
                                        </span>

                                      </div>
                                    </div>
                                  );
                                })
                                }
                                <div style={{ textAlign: "right", width: "100%", padding: "5px 10px", backgroundColor: "rgb(226, 240, 226)" }} className="spanKeyValue" >
                                  Total   <b style={{ marginLeft: "10px" }}>{mouvement.montant}€ </b>
                                </div>
                              </>
                            )}
                            <label className="labelKeyValue" >
                              Créé le
                              <span>{getDateTraining(mouvement.date)}</span>
                            </label>
                            <label className="labelKeyValue" >
                              Créé par
                              <span>{(user && mouvement.createur === user?.uid) ? "Vous" : createur.displayName + " " + createur.lastName}</span>
                            </label>
                            <label className="labelKeyValue" >
                              Pour
                              <span>{(user && mouvement.licencie === user?.uid) ? "Vous" : lic.displayName + " " + lic.lastName}</span>
                            </label>
                            <label className="labelKeyValue" >
                              Mode de réglement
                              <span style={{ flex: "1 1 0", textAlign: "right" }}>{mouvement.typeReglement}</span>
                            </label>
                            <span style={{ flex: "1 1 0" }}>{mouvement.detail}</span>
                            {mouvement.typeReglement !== TypeReglement.VIREMENT_BANCAIRE && mouvement.typeReglement !== TypeReglement.PORTE_FEUILLE &&
                              <label className="labelKeyValue" >
                                Remis à
                                <span style={{ flex: "1 1 0", textAlign: "right" }}>{mouvement.remiseMembreBureau && remiseMembre.displayName}</span>
                              </label>
                            }
                            <StatusTransaction
                              typeMouvement={mouvement.typeMouvement}
                              typeReglement={mouvement.typeReglement}
                              status={mouvement.status}
                              remiseMembreBureau={mouvement.remiseMembreBureau}
                              distribuePar={mouvement.distribuePar}
                              dansCoffre={mouvement.dansCoffre}
                              alreadyDistrib={mouvement.alreadyDistrib}
                            />
                            {
                              mouvement.status === StatusMouvement.ATTENTE_DISTRIBUTION && (isAdmin || isTresorier) &&
                              <div
                                style={{ width: "100%" }}
                                className="btn btn-custom5"
                                onClick={() => {
                                  handleShowConfirmation(
                                    "",
                                    mouvement.typeReglement === TypeReglement.PORTE_FEUILLE ? "Cette commande n'est pas payé ?" : "Cette commande n'est pas validé ?",
                                    "danger",
                                    mouvement.typeReglement === TypeReglement.PORTE_FEUILLE ? "Pas payé ?" : "Pas validé ?",
                                    "Annuler",
                                    null,
                                    mouvement.typeReglement === TypeReglement.PORTE_FEUILLE ? () => onNotPaiementMouvement(mouvement) : () => onNotValidMouvement(mouvement)
                                  );
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faTimes}
                                  style={{ fontSize: "1em", marginRight: "5px", color: "red" }}
                                />
                                {mouvement.typeReglement === TypeReglement.PORTE_FEUILLE ? "Pas payé" : "Pas validé"}
                              </div>
                            }
                          </Accordion.Body>
                        </Accordion.Item>
                      );
                    })
                  }
                </Accordion>
              </>
            )}
            <h5 className="achatCloture">Clôturés</h5>
            {!showMouvementClotureAchat ?
              <div
                style={{ width: "100%" }}
                className="btn btn-custom2"
                onClick={() => setShowMouvementClotureAchat(true)}
              >
                <FontAwesomeIcon
                  icon={faEye}
                  style={{ fontSize: "1em", marginRight: "5px" }}
                />
                Voir tout
              </div>
              : mouvementsAchatOther.length === 0 ? (
                <Container style={{ marginBottom: "10px" }}><span>-</span></Container>) : (
                <>
                  <Accordion style={{ width: "100%", marginBottom: "10px" }}>
                    {
                      mouvementsAchatOther.map((mouvement, index) => {
                        const lic = mouvement.licencie;
                        const createur = mouvement.createur;
                        const remiseMembre = mouvement.remiseMembreBureau;
                        let panier = null;
                        if (mouvement.typeMouvement === TypeMouvement.ACHAT) {
                          panier = mouvement.panier;
                        }
                        return (
                          <Accordion.Item eventKey={index} key={index}>
                            <Accordion.Header>
                              <div
                                style={{
                                  display: "flex",
                                  width: "100%",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  marginRight: "10px"
                                }}
                              >
                                <div style={{ display: "flex", flex: "1 1 auto", justifyContent: "space-between", alignItems: "center" }}>
                                  <div style={{ display: "flex", flex: "1 1 auto", flexDirection: "column", justifyContent: "space-between" }}>
                                    <b><span>{mouvement.motif}</span></b>
                                    <span style={{ fontSize: "0.8em" }}>{lic.firstName + " " + lic.lastName}</span>
                                  </div>
                                </div>
                                <span style={{ minWidth: "70px", fontWeight: "bold", textAlign: "right" }}>{(mouvement.montant > 0 ? "+" : "") + mouvement.montant}€</span>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              {panier && (
                                <>
                                  <div style={{
                                    padding: "5px 10px",
                                    width: "100%",
                                    backgroundColor: "rgb(226, 240, 226)"
                                  }}><b>Récapitulatif</b></div>

                                  {panier && panier.map((article, index) => {
                                    return (
                                      <div
                                        key={index}
                                        style={{
                                          fontSize: "1em",
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "space-between",
                                          width: "100%",
                                          alignItems: "flex-start",
                                          padding: "5px 5px",
                                          backgroundColor: "rgb(226, 240, 226)"
                                        }}
                                      >
                                        <div
                                          key={index}
                                          style={{
                                            fontSize: "0.8em",
                                            flex: "1 1 auto",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            width: "100%",
                                            alignItems: "center",
                                            padding: "0px 5px",
                                            borderBottom: "1px solid gray"
                                          }}
                                        >
                                          <span style={{ minWidth: "30px", textAlign: "left", marginRight: "5px" }}>
                                            x {article["nb"]}
                                          </span>
                                          <span style={{
                                            flex: "1 1 auto",
                                          }}>
                                            {article.title} {article.withTaille && " - " + article.tailleGenre + " - Taille " + article.taille}
                                          </span>
                                          <span style={{ minWidth: "70px", textAlign: "right" }}>
                                            {article.prix * article["nb"]} €
                                          </span>

                                        </div>
                                      </div>
                                    );
                                  })
                                  }
                                  <div style={{ textAlign: "right", width: "100%", padding: "5px 10px", backgroundColor: "rgb(226, 240, 226)" }} className="spanKeyValue" >
                                    Total   <b style={{ marginLeft: "10px" }}>{mouvement.montant}€ </b>
                                  </div>
                                </>
                              )}
                              <label className="labelKeyValue" >
                                Créé le
                                <span>{getDateTraining(mouvement.date)}</span>
                              </label>
                              <label className="labelKeyValue" >
                                Créé par
                                <span>{(user && mouvement.createur === user?.uid) ? "Vous" : createur.displayName + " " + createur.lastName}</span>
                              </label>
                              <label className="labelKeyValue" >
                                Pour
                                <span>{(user && mouvement.licencie === user?.uid) ? "Vous" : lic.displayName + " " + lic.lastName}</span>
                              </label>
                              <label className="labelKeyValue" >
                                Mode de réglement
                                <span style={{ flex: "1 1 0", textAlign: "right" }}>{mouvement.typeReglement}</span>
                              </label>
                              <span style={{ flex: "1 1 0" }}>{mouvement.detail}</span>
                              {mouvement.typeReglement !== TypeReglement.VIREMENT_BANCAIRE && mouvement.typeReglement !== TypeReglement.PORTE_FEUILLE &&
                                <label className="labelKeyValue" >
                                  Remis à
                                  <span style={{ flex: "1 1 0", textAlign: "right" }}>{mouvement.remiseMembreBureau && remiseMembre.displayName}</span>
                                </label>
                              }
                              <label className="labelKeyValue" >
                                Distribué par
                                <span style={{ flex: "1 1 0", textAlign: "right" }}>{mouvement.distribuePar && mouvement.distribuePar.displayName}</span>
                              </label>
                              <StatusTransaction
                                typeMouvement={mouvement.typeMouvement}
                                typeReglement={mouvement.typeReglement}
                                status={mouvement.status}
                                remiseMembreBureau={mouvement.remiseMembreBureau}
                                distribuePar={mouvement.distribuePar}
                                dansCoffre={mouvement.dansCoffre}
                                alreadyDistrib={mouvement.alreadyDistrib}
                              />
                              {
                                mouvement.status === StatusMouvement.CLOTURE && (isAdmin || isTresorier) &&
                                <div
                                  style={{ width: "100%" }}
                                  className="btn btn-custom5"
                                  onClick={() => {
                                    handleShowConfirmation(
                                      mouvement.typeMouvement === TypeMouvement.DEPOT ? "Pas valider ?" : "Pas distribuer ?",
                                      mouvement.typeMouvement === TypeMouvement.DEPOT ? "Solde actuel : " + (lic ? lic["porte-feuille"].toFixed(2) : "0") + " € / Solde futur : " + ((lic ? lic["porte-feuille"] : 0) - mouvement.montant).toFixed(2) + " €" : "La commande n'a pas été distribuée ?",
                                      "danger",
                                      mouvement.typeMouvement === TypeMouvement.DEPOT ? "Pas validé" : "Pas distribué",
                                      "Annuler",
                                      null,
                                      mouvement.typeMouvement === TypeMouvement.DEPOT ? () => onNotValidMouvement(mouvement) : () => onNotDistribueMouvement(mouvement)
                                    );
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faTimes}
                                    style={{ fontSize: "1em", marginRight: "5px", color: "red" }}
                                  />
                                  {mouvement.typeMouvement === TypeMouvement.DEPOT ? "Pas validé" : "Pas distribué"}
                                </div>
                              }
                            </Accordion.Body>
                          </Accordion.Item>
                        );
                      })
                    }
                  </Accordion>
                </>
              )}
          </Container>
          <ModalMouvement
            show={modalMouvementShow}
            mouvement={currentTypeMouvement}
            currentUser={currentUser}
            onHide={() => setModalMouvementShow(false)}
            onValid={onValid}
            onCancel={onCancel}
          />
        </Container >

      </Tab>
      <Tab eventKey="portefeuille"
        title=<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <FontAwesomeIcon icon={faWallet} style={{ fontSize: "1em", marginRight: "5px" }} /> Portefeuille
          {badgeDepotCompt > 0 && <Badge bg="warning" style={{ color: "black", border: "1px solid gray", padding: "3px 5px" }}>{badgeDepotCompt}</Badge>}
        </div>
      >

        <Container>
          <div
            className="section"
            style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
          >
            <Button style={{ width: "100%" }} className="btn btn-custom2" onClick={ajoutDepot}>
              Ajouter un dépôt
            </Button>
          </div>
          <Container
            className="tresorerieListStatus"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <h5 className="depotToDepose">À remettre à la trésorière</h5>
            {mouvementsDepotToDepose.length === 0 ? (
              <Container style={{ marginBottom: "10px" }}><span>Rien à remettre à la trésorière</span></Container>) : (
              <>
                <Accordion style={{ width: "100%", marginBottom: "10px" }}>
                  {mouvementsDepotToDepose.map((mouvement, index) => {
                    const lic = mouvement.licencie;
                    const createur = mouvement.createur;
                    const remiseMembre = mouvement.remiseMembreBureau;
                    const isResponsable = user && mouvement.remiseMembreBureau === user?.uid;
                    const canDepose = isAdmin || (mouvement.typeReglement !== TypeReglement.VIREMENT_BANCAIRE && user && user?.uid === mouvement.remiseMembreBureau);
                    const isCreateur = user && mouvement.createur === user?.uid;
                    const canDelete = isAdmin || isCreateur;
                    return (
                      <Accordion.Item eventKey={index} key={index}  >
                        <Accordion.Header >
                          <div style={{ backgroundColor: isResponsable ? "" : "", display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                            <div style={{ display: "flex", flex: "1 1 auto", justifyContent: "space-between", alignItems: "center" }}>
                              <div style={{ display: "flex", flex: "1 1 auto", flexDirection: "column", justifyContent: "space-between" }}>
                                <b><span>{mouvement.motif}</span></b>
                                <span style={{ fontSize: "0.8em" }}>{lic.firstName + " " + lic.lastName}</span>
                              </div>
                              <span style={{ minWidth: "70px", fontWeight: "bold", textAlign: "right" }}>{(mouvement.montant > 0 ? "+" : "") + mouvement.montant}€</span>
                            </div>

                            <div
                              className="btn btn-custom2"
                              disabled={!canDepose}
                              onClick={(e) => {
                                if (!canDepose) return;
                                e.preventDefault();
                                e.stopPropagation();
                                handleShowConfirmation(
                                  "C'est remis",
                                  "L'argent a-t-il été remis à la trésorière ?",
                                  "success",
                                  "Oui",
                                  "Non",
                                  null,
                                  () => onDeposeMouvement(mouvement, false)
                                )
                              }}
                            >
                              Remettre à la trésorière
                            </div>
                            <div
                              className="btn btn-custom2"
                              disabled={!canDepose}
                              onClick={(e) => {
                                if (!canDepose) return;
                                e.preventDefault();
                                e.stopPropagation();
                                handleShowConfirmation(
                                  "C'est déposé",
                                  "L'argent a-t-il été déposé dans le coffre ?",
                                  "success",
                                  "Oui",
                                  "Non",
                                  null,
                                  () => onDeposeMouvement(mouvement, true)
                                )
                              }}
                            >
                              Déposer dans le coffre
                            </div>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <label className="labelKeyValue" >
                            Créé le
                            <span>{getDateTraining(mouvement.date)}</span>
                          </label>
                          <label className="labelKeyValue" >
                            Créé par
                            <span>{(user && mouvement.createur === user?.uid) ? "Vous" : createur.displayName + " " + createur.lastName}</span>
                          </label>
                          <label className="labelKeyValue" >
                            Pour
                            <span>{(user && mouvement.licencie === user?.uid) ? "Vous" : lic.displayName + "  " + lic.lastName}</span>
                          </label>
                          <label className="labelKeyValue" >
                            Mode de réglement
                            <span style={{ flex: "1 1 0", textAlign: "right" }}>{mouvement.typeReglement}</span>
                          </label>
                          <span style={{ flex: "1 1 0" }}>{mouvement.detail}</span>
                          {mouvement.typeReglement !== TypeReglement.VIREMENT_BANCAIRE && mouvement.typeReglement !== TypeReglement.PORTE_FEUILLE &&
                            <label className="labelKeyValue" >
                              Remis à
                              <span style={{ flex: "1 1 0", textAlign: "right" }}>{mouvement.remiseMembreBureau && remiseMembre.displayName}</span>
                            </label>
                          }
                          <StatusTransaction
                            typeMouvement={mouvement.typeMouvement}
                            typeReglement={mouvement.typeReglement}
                            status={mouvement.status}
                            remiseMembreBureau={mouvement.remiseMembreBureau}
                            dansCoffre={mouvement.dansCoffre}
                            alreadyDistrib={mouvement.alreadyDistrib}
                          />
                          {
                            mouvement.status === StatusMouvement.ATTENTE_DEPOT && user && (user?.uid === mouvement.createur || isAdmin) &&
                            <div
                              style={{ width: "100%" }}
                              className="btn btn-custom5"
                              disabled={!canDelete}
                              onClick={() => {
                                if (!canDelete) return;
                                handleShowConfirmation(
                                  "",
                                  "Etes vous sur de vouloir retirer ce dépôt ?",
                                  "danger",
                                  "Retirer",
                                  "Annuler",
                                  null,
                                  () => handleDeleteMouvement(mouvement)
                                );
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faTimes}
                                style={{ fontSize: "1em", marginRight: "5px", color: "red" }}
                              />
                              Retirer
                            </div>
                          }
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>
              </>
            )}
            <h5 className="depotToValid">À valider</h5>
            {mouvementsDepotToValid.length === 0 ? (
              <Container style={{ marginBottom: "10px" }}><span>Rien à valider</span></Container>) : (
              <>
                <Accordion style={{ width: "100%", marginBottom: "10px" }}>
                  {mouvementsDepotToValid.map((mouvement, index) => {
                    const lic = mouvement.licencie;
                    const createur = mouvement.createur;
                    const remiseMembre = mouvement.remiseMembreBureau;
                    const canValid = isAdmin || isTresorier;
                    const canNotDepose = isAdmin || (mouvement.typeReglement !== TypeReglement.VIREMENT_BANCAIRE && user && user?.uid === mouvement.remiseMembreBureau);

                    return (
                      <Accordion.Item eventKey={index} key={index}>
                        <Accordion.Header>
                          <div key={index} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }} >
                            <div style={{ display: "flex", flex: "1 1 auto", justifyContent: "space-between", alignItems: "center" }}>
                              <div style={{ display: "flex", flex: "1 1 auto", flexDirection: "column", justifyContent: "space-between" }}>
                                <b><span>{mouvement.motif}</span></b>
                                <span style={{ fontSize: "0.8em" }}>{lic.firstName + " " + lic.lastName}</span>
                              </div>
                              <span style={{ minWidth: "70px", fontWeight: "bold", textAlign: "right" }}>{(mouvement.montant > 0 ? "+" : "") + mouvement.montant}€</span>
                            </div>

                            <div
                              className="btn btn-custom2"
                              disabled={!canValid}
                              onClick={(e) => {
                                if (!canValid) return;
                                e.preventDefault();
                                e.stopPropagation();
                                handleShowConfirmation(
                                  "C'est encaissé ?",
                                  "Solde | Actuel : " + (lic ? lic["porte-feuille"].toFixed(2) : 0) + " € / Futur : " + ((lic ? lic["porte-feuille"] : 0) + mouvement.montant).toFixed(2) + " €",
                                  "success",
                                  "Valider",
                                  "Annuler",
                                  null,
                                  () => onValidMouvement(mouvement)
                                )
                              }}
                            >
                              Valider
                            </div>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <label className="labelKeyValue" >
                            Créé par
                            <span>{(user && mouvement.createur === user?.uid) ? "Vous" : createur.displayName + "  " + createur.lastName}</span>
                          </label>
                          <label className="labelKeyValue" >
                            Licencié
                            <span>{(user && mouvement.licencie === user?.uid) ? "Vous" : lic.displayName + "  " + lic.lastName}</span>
                          </label>
                          <label className="labelKeyValue" >
                            Date
                            <span>{getDateTraining(mouvement.date)}</span>
                          </label>
                          <label className="labelKeyValue" >
                            Mode de réglement
                            <span style={{ flex: "1 1 0", textAlign: "right" }}>{mouvement.typeReglement}</span>
                          </label>
                          <span style={{ flex: "1 1 0" }}>{mouvement.detail}</span>
                          {mouvement.typeReglement !== TypeReglement.VIREMENT_BANCAIRE && mouvement.typeReglement !== TypeReglement.PORTE_FEUILLE &&
                            <label className="labelKeyValue" >
                              Remis à
                              <span style={{ flex: "1 1 0", textAlign: "right" }}>{mouvement.remiseMembreBureau && remiseMembre.displayName}</span>
                            </label>
                          }
                          <StatusTransaction
                            typeMouvement={mouvement.typeMouvement}
                            typeReglement={mouvement.typeReglement}
                            status={mouvement.status}
                            remiseMembreBureau={mouvement.remiseMembreBureau}
                            dansCoffre={mouvement.dansCoffre}
                            alreadyDistrib={mouvement.alreadyDistrib}
                          />
                          {
                            mouvement.status === StatusMouvement.ATTENTE_VALIDATION && user && (user?.uid === mouvement.createur || isAdmin) &&
                            <div
                              style={{ width: "100%" }}
                              className="btn btn-custom5"
                              disabled={!canNotDepose}
                              onClick={() => {
                                if (!canNotDepose) return;
                                handleShowConfirmation(
                                  "",
                                  mouvement.remiseMembreBureau ? "Vous n'avez pas déposer l'argent ?" : "Etes vous sur de vouloir retirer ce dépôt ?",
                                  "danger",
                                  mouvement.remiseMembreBureau ? "Argent non déposé" : "Retirer",
                                  "Annuler",
                                  null,
                                  mouvement.remiseMembreBureau ? () => onNotDeposeMouvement(mouvement) : () => handleDeleteMouvement(mouvement)
                                );
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faTimes}
                                style={{ fontSize: "1em", marginRight: "5px", color: "red" }}
                              />
                              {mouvement.typeReglement === TypeReglement.VIREMENT_BANCAIRE ? "Retirer" : (mouvement.dansCoffre ? "Pas déposé ?" : "Pas remis ?")}
                            </div>
                          }
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>
              </>
            )}
            <h5 className="depotCloture">Clôturés</h5>
            {!showMouvementClotureDepot ?
              <div
                style={{ width: "100%" }}
                className="btn btn-custom2"
                onClick={() => setShowMouvementClotureDepot(true)}
              >
                <FontAwesomeIcon
                  icon={faEye}
                  style={{ fontSize: "1em", marginRight: "5px" }}
                />
                Voir tout
              </div>
              : mouvementsDepotOther.length === 0 ? (
                <Container style={{ marginBottom: "10px" }}><span>-</span></Container>) : (
                <>
                  <Accordion style={{ width: "100%", marginBottom: "10px" }}>
                    {
                      mouvementsDepotOther.map((mouvement, index) => {
                        const lic = mouvement.licencie;
                        const createur = mouvement.createur;
                        const remiseMembre = mouvement.remiseMembreBureau;
                        return (
                          <Accordion.Item eventKey={index} key={index}>
                            <Accordion.Header>
                              <div
                                style={{
                                  display: "flex",
                                  width: "100%",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  marginRight: "10px"
                                }}
                              >
                                <div style={{ display: "flex", flex: "1 1 auto", justifyContent: "space-between", alignItems: "center" }}>
                                  <div style={{ display: "flex", flex: "1 1 auto", flexDirection: "column", justifyContent: "space-between" }}>
                                    <b><span>{mouvement.motif}</span></b>
                                    <span style={{ fontSize: "0.8em" }}>{lic.firstName + " " + lic.lastName}</span>
                                  </div>
                                </div>
                                <span style={{ minWidth: "70px", fontWeight: "bold", textAlign: "right" }}>{(mouvement.montant > 0 ? "+" : "") + mouvement.montant}€</span>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <label className="labelKeyValue" >
                                Créé par
                                <span>{(user && mouvement.createur === user?.uid) ? "Vous" : createur.displayName + " " + createur.lastName}</span>
                              </label>
                              <label className="labelKeyValue" >
                                Licencié
                                <span>{(user && mouvement.licencie === user?.uid) ? "Vous" : lic.displayName + " " + lic.lastName}</span>
                              </label>
                              <label className="labelKeyValue" >
                                Date
                                <span>{getDateTraining(mouvement.date)}</span>
                              </label>
                              <label className="labelKeyValue" >
                                Mode de réglement
                                <span style={{ flex: "1 1 0", textAlign: "right" }}>{mouvement.typeReglement}</span>
                              </label>
                              <span style={{ flex: "1 1 0" }}>{mouvement.detail}</span>
                              {mouvement.typeReglement !== TypeReglement.VIREMENT_BANCAIRE && mouvement.typeReglement !== TypeReglement.PORTE_FEUILLE &&
                                <label className="labelKeyValue" >
                                  Remis à
                                  <span style={{ flex: "1 1 0", textAlign: "right" }}>{mouvement.remiseMembreBureau && remiseMembre.displayName}</span>
                                </label>
                              }
                              <StatusTransaction
                                typeMouvement={mouvement.typeMouvement}
                                typeReglement={mouvement.typeReglement}
                                status={mouvement.status}
                                remiseMembreBureau={mouvement.remiseMembreBureau}
                                dansCoffre={mouvement.dansCoffre}
                                alreadyDistrib={mouvement.alreadyDistrib}
                              />
                              {
                                mouvement.status === StatusMouvement.CLOTURE && (isAdmin || isTresorier) &&
                                <div
                                  style={{ width: "100%" }}
                                  className="btn btn-custom5"
                                  onClick={() => {
                                    handleShowConfirmation(
                                      "Pas validé ?",
                                      "Solde | Actuel : " + (lic ? lic["porte-feuille"].toFixed(2) : 0) + " € / Futur : " + ((lic ? lic["porte-feuille"] : 0) - mouvement.montant).toFixed(2) + " €",
                                      "danger",
                                      "Pas validé",
                                      "Annuler",
                                      null,
                                      () => onNotValidMouvement(mouvement)
                                    );
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faTimes}
                                    style={{ fontSize: "1em", marginRight: "5px", color: "red" }}
                                  />
                                  {mouvement.typeMouvement === TypeMouvement.DEPOT ? "Pas validé" : "Pas distribué"}
                                </div>
                              }
                            </Accordion.Body>
                          </Accordion.Item>
                        );
                      })
                    }
                  </Accordion>
                </>
              )}
          </Container>
          <ModalMouvement
            show={modalMouvementShow}
            typeMouvement={currentTypeMouvement}
            currentUser={currentUser}
            onHide={() => setModalMouvementShow(false)}
            onValid={onValid}
            onCancel={onCancel}
          />
        </Container >

      </Tab>
    </Tabs >
  );
}

export default ManagementTresorerie;
