import React from "react";
import { useGlobalContext } from "./GlobalContext.js";

function StatusTransaction({ typeMouvement, typeReglement, status, remiseMembreBureau, distribuePar, dansCoffre, alreadyDistrib }) {
  const {
    StatusMouvement,
    TypeMouvement,
    TypeReglement
  } = useGlobalContext();

  const fluxDepot = [
    StatusMouvement.ATTENTE_PAIEMENT,
    StatusMouvement.ATTENTE_DEPOT,
    StatusMouvement.ATTENTE_VALIDATION,
  ].map((currentState, index) => {
    //console.log("fluxDepot");
    const isReached = ((status === StatusMouvement.ATTENTE_DEPOT && currentState === StatusMouvement.ATTENTE_PAIEMENT) ||
      (status === StatusMouvement.ATTENTE_VALIDATION && (currentState === StatusMouvement.ATTENTE_PAIEMENT || currentState === StatusMouvement.ATTENTE_DEPOT)) ||
      status === StatusMouvement.CLOTURE);
    return (
      <span
        key={index}
        style={{
          fontSize: "0.7em", flex: "1", height: "55px", textAlign: "center"
        }}
        variant="secondary"
        className={isReached ? "statusMouvement reached" : "statusMouvement"}
      >
        {
          isReached ? (
            currentState === StatusMouvement.ATTENTE_PAIEMENT ?
              ("Remis à " + (remiseMembreBureau ? remiseMembreBureau.displayName : "")) :
              currentState === StatusMouvement.ATTENTE_DEPOT ? (dansCoffre ? "Déposé dans le coffre " : "Remis à la trésorière") :
                currentState === StatusMouvement.ATTENTE_VALIDATION ? "Validé" : "Clôturé"
          ) : (currentState)}
      </span >
    );
  });

  const fluxDepotVirement = [
    StatusMouvement.ATTENTE_PAIEMENT,
    StatusMouvement.ATTENTE_VALIDATION,
  ].map((currentState, index) => {
    const isReached = ((status === StatusMouvement.ATTENTE_VALIDATION && currentState === StatusMouvement.ATTENTE_PAIEMENT) ||
      (status === StatusMouvement.ATTENTE_DISTRIBUTION && (currentState === StatusMouvement.ATTENTE_PAIEMENT || currentState === StatusMouvement.ATTENTE_VALIDATION)) ||
      status === StatusMouvement.CLOTURE);
    return (
      <span
        key={index}
        style={{
          fontSize: "0.7em", flex: "1", height: "55px", textAlign: "center"
        }}
        variant="secondary"
        className={isReached ? "statusMouvement reached" : "statusMouvement"}
      >
        {
          isReached ? (
            currentState === StatusMouvement.ATTENTE_PAIEMENT ?
              "Payé" :
              currentState === StatusMouvement.ATTENTE_VALIDATION ? "Validé" : "Clôturé"
          ) : (currentState)}
      </span >
    );
  });

  const fluxAchat = [
    StatusMouvement.ATTENTE_PAIEMENT,
    StatusMouvement.ATTENTE_DEPOT,
    StatusMouvement.ATTENTE_VALIDATION,
    StatusMouvement.ATTENTE_DISTRIBUTION,
  ].map((currentState, index) => {
    //console.log("fluxAchat");
    const isReached = ((status === StatusMouvement.ATTENTE_DEPOT && currentState === StatusMouvement.ATTENTE_PAIEMENT) ||
      (status === StatusMouvement.ATTENTE_VALIDATION && (currentState === StatusMouvement.ATTENTE_PAIEMENT || currentState === StatusMouvement.ATTENTE_DEPOT)) ||
      (status === StatusMouvement.ATTENTE_DISTRIBUTION && (currentState === StatusMouvement.ATTENTE_PAIEMENT || currentState === StatusMouvement.ATTENTE_DEPOT || currentState === StatusMouvement.ATTENTE_VALIDATION)) ||
      status === StatusMouvement.CLOTURE) ||
      (currentState === StatusMouvement.ATTENTE_DISTRIBUTION && alreadyDistrib);
    return (
      <span
        key={index}
        style={{ fontSize: isReached ? "0.7em" : "0.7em", flex: "1", height: "55px", textAlign: "center" }}
        variant="secondary"
        className={isReached ? "statusMouvement reached" : "statusMouvement"}
      >
        {isReached ? (
          currentState === StatusMouvement.ATTENTE_PAIEMENT ?
            ("Remis à " + (remiseMembreBureau ? remiseMembreBureau.displayName : "")) :
            currentState === StatusMouvement.ATTENTE_DEPOT ? (dansCoffre ? "Déposé dans le coffre " : "Remis à la trésorière") :
              currentState === StatusMouvement.ATTENTE_VALIDATION ? "Validé" :
                currentState === StatusMouvement.ATTENTE_DISTRIBUTION ? "Distribué par " + (distribuePar ? distribuePar.displayName : "") : "Clôturé"
        ) : (currentState)}
      </span>
    );
  })

  const fluxAchatPortefeuille = [
    StatusMouvement.ATTENTE_PAIEMENT,
    StatusMouvement.ATTENTE_DISTRIBUTION,
  ].map((currentState, index) => {
    const isReached = ((status === StatusMouvement.ATTENTE_DISTRIBUTION && currentState === StatusMouvement.ATTENTE_PAIEMENT) ||
      status === StatusMouvement.CLOTURE);
    return (
      <span
        key={index}
        style={{ fontSize: "0.7em", flex: "1", height: "55px", textAlign: "center" }}
        variant="secondary"
        className={isReached ? "statusMouvement reached" : "statusMouvement"}
      >
        {isReached ? (
          currentState === StatusMouvement.ATTENTE_PAIEMENT ?
            "Payé" :
            currentState === StatusMouvement.ATTENTE_DISTRIBUTION ? "Distribué par " + (distribuePar ? distribuePar.displayName : "") : "Clôturé"
        ) : (currentState)}
      </span>
    );
  });

  const fluxAchatVirement = [
    StatusMouvement.ATTENTE_PAIEMENT,
    StatusMouvement.ATTENTE_VALIDATION,
    StatusMouvement.ATTENTE_DISTRIBUTION,
  ].map((currentState, index) => {
    const isReached = ((status === StatusMouvement.ATTENTE_VALIDATION && currentState === StatusMouvement.ATTENTE_PAIEMENT) ||
      (status === StatusMouvement.ATTENTE_DISTRIBUTION && (currentState === StatusMouvement.ATTENTE_PAIEMENT || currentState === StatusMouvement.ATTENTE_VALIDATION)) ||
      status === StatusMouvement.CLOTURE);
    return (
      <span
        key={index}
        style={{ fontSize: "0.7em", flex: "1", height: "55px", textAlign: "center" }}
        variant="secondary"
        className={isReached ? "statusMouvement reached" : "statusMouvement"}
      >
        {isReached ? (
          currentState === StatusMouvement.ATTENTE_PAIEMENT ?
            "Payé" :
            currentState === StatusMouvement.ATTENTE_VALIDATION ? "Validé" :
              currentState === StatusMouvement.ATTENTE_DISTRIBUTION ? "Distribué par " + (distribuePar ? distribuePar.displayName : "") : "Clôturé"
        ) : (currentState)}
      </span>
    );
  });


  /*useEffect(() => {
    const fetch = async () => {
      if (mouvement.typeReglement !== TypeReglement.VIREMENT_BANCAIRE) {
        remiseMembre.current = await getLicencie(mouvement.remiseMembreBureau);
      }
      setLoading(false);
    }
    fetch();
  }, [StatusMouvement, getLicencie, mouvement, TypeReglement])*/


  /*if (loading) {
    return <Placeholder as="div" animation="glow" style={{ width: '100%', height: "100px", marginBottom: "10px" }} >
      <Placeholder style={{ width: '100%', height: "100%", borderRadius: "5px" }} />
    </Placeholder>
  }*/

  return (
    <div style={{ backgroundColor: "rgb(230,230,230)", marginTop: "30px", marginBottom: "10px", paddingTop: "10px", width: "100%", borderTop: "1px solid rgba(0, 128, 0, 0.288)", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      {typeMouvement === TypeMouvement.DEPOT ?
        (typeReglement === TypeReglement.VIREMENT_BANCAIRE ? fluxDepotVirement : fluxDepot)
        : (typeReglement === TypeReglement.VIREMENT_BANCAIRE ? fluxAchatVirement :
          (typeReglement === TypeReglement.PORTE_FEUILLE ? fluxAchatPortefeuille : fluxAchat))}
    </div>
  );
}

export default StatusTransaction;
