import React, { useState, useEffect } from 'react';
import { Container, Button, Placeholder, Accordion, FloatingLabel, Form } from 'react-bootstrap';
import { useGlobalContext } from "./GlobalContext";
import NumberSpinner from "./NumberSpinner.js";
import { useNavigate } from "react-router-dom";
import {
  onSnapshot,
  query,
  collection,
  where
} from "firebase/firestore";

function Shop() {

  const { db, PageUrl, user, getPhotoArticle, TypeMouvement, StatusMouvement, TypeReglement, TailleHomme, TailleFemme, TailleEnfant } = useGlobalContext();
  const navigate = useNavigate();
  const [articles, setArticles] = useState([]);
  const [panier, setPanier] = useState([]);
  const [loading, setLoading] = useState(true);

  if (user) {
    if (user.panier) setPanier(user.panier);
  }

  const validePanier = async () => {
    await db.addMouvement(
      {
        typeMouvement: TypeMouvement.ACHAT,
        motif: TypeMouvement.ACHAT,
        typeReglement: TypeReglement.PORTE_FEUILLE,
        montant: panier.reduce((accumulateur, t) => accumulateur + t["prix"] * t["nb"], 0),
        status: StatusMouvement.ATTENTE_PAIEMENT,
        remiseMembreBureau: null,
        licencie: user.uid,
        createur: user.uid,
        panier: panier
      });
    navigate(PageUrl.EDIT_PROFIL + "/commandes", { to: PageUrl.EDIT_PROFIL });
    //handleShowAlert("Votre commande est validée", "Vous pouvez suivre son avancement dans votre compte", "success");
  }

  const afterChangeValue = async (article, nb) => {
    const ind = panier.findIndex(a => a.uid === article.uid);
    if (ind !== -1) {
      if (nb === 0) {
        panier.splice(ind, 1);
      } else {
        panier[ind]["nb"] = nb;
      }
      setPanier([...panier]);
    } else {
      const newPanier = panier;
      article["nb"] = nb;
      newPanier.push(article);
      setPanier([...newPanier]);
    }
  }

  const afterChangeValueWithTaille = async (article, nb, tailleGenre, taille) => {
    const ind = panier.findIndex(a => a.uid === article.uid && a.taille === taille);
    if (ind !== -1) {
      if (nb === 0) {
        panier.splice(ind, 1);
      } else {
        panier[ind]["nb"] = nb;
      }
      setPanier([...panier]);
    } else {
      const newPanier = panier;
      const newArticle = JSON.parse(JSON.stringify(article));
      newArticle["nb"] = nb;
      newArticle["withTaille"] = true;
      newArticle["taille"] = taille;
      newArticle["tailleGenre"] = tailleGenre;
      newPanier.push(newArticle);
      setPanier([...newPanier]);
    }
  }

  const afterChangeValueFlocageAvant = async (flocageAvant, article) => {
    const ind = panier.findIndex(a => a.uid === article.uid);
    if (ind !== -1) {
      panier[ind]["flocageAvant"] = flocageAvant;
      setPanier([...panier]);
    }
  }

  useEffect(() => {
    const q = query(collection(db.db, "clubs/badlevier/articles"), where("visible", "==", true));
    const unsubscribeArticle = onSnapshot(q, async (querySnapshotArticle) => {
      const retour = [];
      await Promise.all(
        querySnapshotArticle.docs.map(async (doc) => {
          const article = doc.data();
          const articleFindIndex = retour.findIndex(cre => cre.uid === article.uid);
          if (articleFindIndex > -1) {
            retour[articleFindIndex] = article;
          } else {
            retour.push(article);
          }
        })
      );
      setArticles([...retour]);
      setLoading(false);
    });

    return () => {
      unsubscribeArticle();
    };

  }, [db]);

  const nbArticlesPanier = panier.reduce((accumulateur, t) => accumulateur + t["nb"], 0)

  return (
    <Container>
      {panier && panier.length > 0 &&
        <div
          className="section"
          style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%"
            }}
          >
            <span style={{ marginLeft: "5px" }}>{nbArticlesPanier} article{nbArticlesPanier > 1 && "s"}</span>
            <span>|</span>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <span style={{ marginRight: "10px", fontWeight: "bold" }}>Total</span>
              <span style={{ fontWeight: "bold" }}>{panier.reduce((accumulateur, t) => accumulateur + t["prix"] * t["nb"], 0)} €</span>
            </div>
            <Button className="btn btn-custom2"
              disabled={panier.length === 0}
              onClick={validePanier}>
              Valider le panier
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              backgroundColor: "white"
            }}
          >
            {panier.map((article, index) => {
              return (
                <div
                  key={index}
                  style={{
                    fontSize: "0.8em",
                    flex: "1 1 auto",
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                    padding: "5px 10px",
                    borderBottom: "1px solid gray"
                  }}
                >
                  <span style={{ minWidth: "30px", textAlign: "left", marginRight: "5px" }}>
                    x {article["nb"]}
                  </span>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{
                      textWrap: "nowrap",
                      textOverflow: "ellipsis",
                      position: "relative",
                      flex: "1 1 auto",
                      overflow: "hidden"
                    }}>
                      {article.title} {article.withTaille && " - " + article.tailleGenre + " - Taille " + article.taille}
                    </span>
                    {article["flocageAvant"] !== "" &&
                      <span style={{ minWidth: "30px", textAlign: "left", marginRight: "5px" }}>
                        <span>Flocage avant : </span>
                        <span style={{ minWidth: "30px", textAlign: "left", marginRight: "5px", backgroundColor: "yellow" }}>
                          {article["flocageAvant"]}
                        </span>
                      </span>
                    }

                  </div>
                  <span style={{ minWidth: "70px", textAlign: "right" }}>
                    {article.prix * article["nb"]} €
                  </span>

                </div>

              );
            })
            }
          </div>
        </div>
      }
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {loading ?
          <Placeholder as="div" animation="glow" style={{ width: '100%', height: "100px", marginBottom: "10px" }} >
            <Placeholder style={{ width: '100%', height: "100%", borderRadius: "5px" }} />
          </Placeholder>
          : articles.length === 0 ? (
            <span style={{ fontStyle: "italic" }}>
              Aucun article
            </span>
          ) : (
            articles.map((article, index) => {
              const ind = panier.findIndex(a => a.uid === article.uid);
              const nbArticleInPanier = ind !== -1 ? panier[ind]["nb"] : 0;
              const reste = article.qte - nbArticleInPanier;
              const withTaille = article.withTaille;
              const qteTailleHommeObj = withTaille && article.qteTailleHomme ? JSON.parse(article.qteTailleHomme) : null;
              const qteTailleFemmeObj = withTaille && article.qteTailleFemme ? JSON.parse(article.qteTailleFemme) : null;
              const qteTailleEnfantObj = withTaille && article.qteTailleEnfant ? JSON.parse(article.qteTailleEnfant) : null;
              const withFlocageAvant = article.withFlocageAvant;
              return (
                <div
                  key={index}
                  style={{
                    flex: "1 1 auto",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    margin: "10px",
                    width: "100%",
                    alignItems: "center",
                    padding: "10px",
                    boxShadow: "0px 0px 10px 2px gray",
                    marginBottom: "10px",
                    backgroundColor: "white"
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontWeight: "bold",
                      marginBottom: "10px",
                      width: "100%"
                    }}
                  >
                    <span>
                      <b>{article.title}</b>
                    </span>
                    <span style={{ minWidth: "70px", textAlign: "right" }}>
                      {article.prix} €
                    </span>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
                    {article.photoUrl &&
                      getPhotoArticle(article.photoUrl, article.title, {
                        maxHeight: "150px",
                        maxWidth: "150px",
                        height: "100%",
                        width: "100%",
                        borderRadius: "5px",
                      })
                    }
                    <span style={{ margin: "0px 5px" }}>{article.description}</span>
                    <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end", width: "100%" }}>
                      {db.isLicencie && !withTaille ?
                        <NumberSpinner
                          minValue={0}
                          maxValue={100 /*article["qte"]*/}
                          firstValue={0}
                          afterChangeValue={(nb) => afterChangeValue(article, nb)}
                        ></NumberSpinner>
                        : db.isLicencie && withTaille ?
                          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                            {withFlocageAvant &&
                              <FloatingLabel controlId="floatingTextarea2" label="Flocage avant" style={{ width: "100%", marginTop: "10px" }}>
                                <Form.Control
                                  className="mb-3"
                                  as="textarea"
                                  placeholder="Si plusieurs articles, préciser la taille et le texte que vous voulez"
                                  style={{ height: "50px" }}
                                  //value=""
                                  onChange={(e) => afterChangeValueFlocageAvant(e.target.value, article)}
                                />
                              </FloatingLabel>
                            }
                            <Accordion
                              defaultActiveKey="0"
                              style={{ maxWidth: "600px", width: "100%" }}
                            >
                              <Accordion.Item
                                eventKey={index}
                                key={index}
                                style={{
                                }}
                              >
                                <Accordion.Header>
                                  <label>Taille Homme</label>
                                </Accordion.Header>
                                <Accordion.Body>
                                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", flexWrap: "wrap" }}>

                                    {TailleHomme.map((currentType, index) => {
                                      const indTaille = panier.findIndex(a => a.uid === article.uid && a.taille === currentType);
                                      const nbArticleInPanierTaille = indTaille !== -1 ? panier[indTaille]["nb"] : 0;
                                      const resteTaille = qteTailleHommeObj["_" + currentType] - nbArticleInPanierTaille;
                                      return (
                                        <div
                                          key={index}
                                          style={{ marginLeft: "10px", display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "5px" }}
                                        >
                                          <label style={{ marginBottom: "10px" }}>{currentType}</label>
                                          <NumberSpinner
                                            minValue={0}
                                            maxValue={100 /*qteTailleHommeObj["_" + currentType]*/}
                                            firstValue={0}
                                            afterChangeValue={(nb) => afterChangeValueWithTaille(article, nb, "Homme", currentType)}
                                          ></NumberSpinner>
                                          <span style={{ marginTop: "5px", fontSize: "0.8em", width: "100%", textAlign: "right", marginRight: "5px", fontWeight: reste < 3 ? "bold" : "", color: reste < 3 ? "darkred" : "" }}>
                                            {resteTaille === 0 ? "Plus de stock" : resteTaille > 0 ? "Il en reste " + resteTaille : Math.abs(resteTaille) + " à commander"}
                                          </span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item>
                                <Accordion.Header>
                                  <label>Taille Femme</label>
                                </Accordion.Header>
                                <Accordion.Body>
                                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", flexWrap: "wrap" }}>
                                    {TailleFemme.map((currentType, index) => {
                                      const indTaille = panier.findIndex(a => a.uid === article.uid && a.taille === currentType);
                                      const nbArticleInPanierTaille = indTaille !== -1 ? panier[indTaille]["nb"] : 0;
                                      const resteTaille = qteTailleFemmeObj["_" + currentType] - nbArticleInPanierTaille;
                                      return (
                                        <div
                                          key={index}
                                          style={{ marginLeft: "10px", display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "5px" }}
                                        >
                                          <label style={{ marginBottom: "10px" }}>{currentType}</label>
                                          <NumberSpinner
                                            minValue={0}
                                            maxValue={100 /*qteTailleFemmeObj["_" + currentType]*/}
                                            firstValue={0}
                                            afterChangeValue={(nb) => afterChangeValueWithTaille(article, nb, "Femme", currentType)}
                                          ></NumberSpinner>
                                          <span style={{ marginTop: "5px", fontSize: "0.8em", width: "100%", textAlign: "right", marginRight: "5px", fontWeight: reste < 3 ? "bold" : "", color: reste < 3 ? "darkred" : "" }}>
                                            {resteTaille === 0 ? "Plus de stock" : resteTaille > 0 ? "Il en reste " + resteTaille : Math.abs(resteTaille) + " à commander"}
                                          </span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item>
                                <Accordion.Header>
                                  <label>Taille Enfant</label>
                                </Accordion.Header>
                                <Accordion.Body>
                                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", flexWrap: "wrap" }}>
                                    {TailleEnfant.map((currentType, index) => {
                                      const indTaille = panier.findIndex(a => a.uid === article.uid && a.taille === currentType);
                                      const nbArticleInPanierTaille = indTaille !== -1 ? panier[indTaille]["nb"] : 0;
                                      const resteTaille = qteTailleEnfantObj["_" + currentType] - nbArticleInPanierTaille;
                                      return (
                                        <div
                                          key={index}
                                          style={{ marginLeft: "10px", display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "5px" }}
                                        >
                                          <label style={{ marginBottom: "10px" }}>{currentType}</label>
                                          <NumberSpinner
                                            minValue={0}
                                            maxValue={100 /*qteTailleFemmeObj["_" + currentType]*/}
                                            firstValue={0}
                                            afterChangeValue={(nb) => afterChangeValueWithTaille(article, nb, "Enfant", currentType)}
                                          ></NumberSpinner>
                                          <span style={{ marginTop: "5px", fontSize: "0.8em", width: "100%", textAlign: "right", marginRight: "5px", fontWeight: reste < 3 ? "bold" : "", color: reste < 3 ? "darkred" : "" }}>
                                            {resteTaille === 0 ? "Plus de stock" : resteTaille > 0 ? "Il en reste " + resteTaille : Math.abs(resteTaille) + " à commander"}
                                          </span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </div>
                          : null
                      }
                    </div>
                    {db.isLicencie && !withTaille &&
                      <span style={{ marginTop: "5px", fontSize: "0.8em", width: "100%", textAlign: "right", marginRight: "5px", fontWeight: reste < 3 ? "bold" : "", color: reste < 3 ? "darkred" : "" }}>
                        {reste === 0 ? "Plus de stock" : reste > 0 ? "Il en reste " + reste : Math.abs(reste) + " à commander"}
                      </span>
                    }
                  </div>
                </div>
              );
            })
          )}
      </Container>
    </Container >
  );
}

export default Shop;
